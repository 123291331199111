.trackerReading-view {
  .div-event-img {
    height: 245px;

    .div-wrapper-event-img {
      z-index: 1;

      background-color: #fff;

      .div-img {
        position: relative;

        .event-img {
          width: 100%;

          z-index: 2;

          .ng-image-gallery-modal {
            height: 225px;

            .galleria-image {
              margin: 0;
            }
          }
        }

        .event-img-png {
          width: 100%;
          height: 225px;

          margin: 0;

          img {
            box-shadow: none;
          }
        }
      }          
    }        

    .event-map-img {
      position: absolute;
      right: 0;
    }
  }

  .list-item {
    padding-top: 12px;

    font-size: 14px;

    color: white;

    .title {
      font-size: 18px;
      font-weight: bold;

      .event-edit-button {
        margin-left: 0;
        padding-left: 0;

        font-size: 22px;
      }
    }

    .info {
      margin-left: 0;
      margin-right: 0;
    }

    .div-event-buttons {
      button {
        margin-top: 4px;
        margin-bottom: 0;
      }
    }

    md-icon {
      color: white;
    }
  }

  md-input-container {
    margin: 0;
  }

  p {
    margin: 0;
  }
}