.pagination-directive {

  button {
    margin: 0 !important;

    @include prefix(transform, opacity .3s ease-in-out);

    &[disabled] {
      opacity: .4;
    }
  }
}