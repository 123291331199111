md-dialog {

  &.line-edit {
    overflow: hidden;

    md-toolbar {

      .title {
        font-size: 17px;
      }
    }

    md-dialog-content {
      position: relative;

      min-height: 250px;

      display: block;

      padding: 16px;

      overflow-x: hidden;

      .div-line-centroids {

        .header-line-centroids {
          height: 46px;
          min-height: 46px;
          width: 108%;

          margin-bottom: 12px;
          margin-left: -20px;
          padding: 12px 16px;

          > span {
            padding: 12px 0 0;
          }
        }

        .div-centroid {

          .line-item {
            cursor: move !important;
          }
        }
      }

      .div-edit-centroid {
        padding: 0 8px;
      }

      md-input-container {

        md-icon {
          color: rgba(0,0,0,0.54);
        }

        > label {
          padding-left: 0 !important;
        }
      }

      mdp-time-picker {

        > div {

          > button {
            width: 32px !important;

            padding-left: 0 !important;
            padding-right: 0 !important;

            md-icon {
              margin-left: 0 !important;
              margin-right: 0 !important;
            }
          }

          md-input-container {
            margin-left: 0 !important;

            @include prefix(flex, 1);
          }
        }
      }
    }

    md-dialog-actions {
      position: relative;
      overflow-y: hidden;

      overflow-x: auto;

      border-top: 1px solid rgba(0, 0, 0, 0.12);

      background-color: rgba(0, 0, 0, 0.03);
    }
  }
}

.div-line-centroids {
  &.as-sortable-dragging {
    background-color: white;

    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);

    opacity: .8 !important;
  }
}

@media screen and (max-width: $layout-breakpoint-xs) {

  md-dialog {

    &.line-edit {
      width: 100%;
    
      .div-input-lines {

        > :not(:first-child) {
          margin-top: 0;
        }
      }

      .div-autocomplete,
      .input-date {
        max-height: 55px;
      }

      .div-autocomplete {
        padding-left: 3px;
        margin: 18px 0;

        &:first-child {
          margin-top: 0;
        }

        md-icon {
          margin-bottom: 5px;
        }
      }

      md-input-container {
        max-height: 70px;
      }
    }
  }
}

@media screen and (min-width: $layout-breakpoint-xs) {

  md-dialog {

    &.line-edit {
      min-width: 600px;
      
      .div-autocomplete {

        &:not(:first-child) {
          margin-left: 10px;
        }
      }

      md-input-container {

        &:not(:first-child) {
          margin-left: 10px;
        }
      }

      md-dialog-content {

        .div-line-inputs {
          max-height: 70px;
        }
      }
    }
  }
}

@media screen and (max-width: $layout-breakpoint-md) {

  md-dialog {

    &.line-edit {
      width: 80%;
    }
  }
}

@media screen and (min-width: $layout-breakpoint-sm) {

  md-dialog {

    &.line-edit {
      max-width: 720px;
      width: 90%;
    }
  }
}

@media screen and (max-width: $layout-breakpoint-sm) {

  md-dialog {

    &.line-edit {
      width: 90%;
    }
  }
}