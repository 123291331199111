md-dialog {

   &.allocationPRODATA-list {
      max-width: 600px;
      min-height: 320px;
      width: 600px;

      md-toolbar {
         background-color: #394b6d;
      }
   	
      .allocation-save-success {
         color: green !important;
      }

      .allocation-save-error {
         color: red !important;
      }

      .background-allocation-save-success {
   		background-color: green !important;
   	}

   	.background-allocation-save-error {
   		background-color: red !important;
   	}

   	.error-msg {
   		font-style: italic;

   		color: rgba(0,0,0,0.54);
   	}

   	.md-subheader .md-subheader-inner {
   		padding-top: 0;
   	}

   	.header-allocationPRODATA-list {
         background-color: #394b6d;
         color: #fff;

         &.md-subheader {
            min-height: 70px;
         }

         .total-allocationPRODATA-list {
            max-height: 25px;
         }

         md-icon {
            margin: 0;

            color: #fff;
         }

   		md-input-container {
   			margin-bottom: 0;

   	    	> * {
   	    		color: #fff !important;
   	    	}

   	    	input {
   	    		border-bottom-color: #fff !important;
   	    	}
         }
    	}

      md-dialog-content {
         position: relative;
         min-height: 200px;

         md-list-item {

            .md-list-item-inner {
               padding: 6px 0;
               z-index: 1;  
            }

            .md-list-item-text {
               
               .title {
                  @extend .text-semibold;
               }

            }
         }

         p {
            margin: 0;
         }
      }
   }

}