.simCards-view {
  max-width: 720px;
  min-height: 420px;
  width: 500px;

 	.md-subheader .md-subheader-inner {
    padding-top: 8px;
 	}

  .header-simCards-view {
    min-height: 80px;
    max-height: 80px;

    background-color: #263238 !important;
    color: #fff !important;

    .md-toolbar-tools-top {

      .title {
        padding-left: 0;
      }
    }

    .info-simCards {
      font-size: 14px;
    }

    md-icon {
      margin-left: 0;
      margin-right: 5px;

      color: #fff;
    }
  }

  .div-floating-list-button {
    bottom: 12px;
  }

  md-content {
    overflow-x: hidden;

    .list-item {
      padding-top: 12px;

      font-size: 14px;

      // color: white;

      .title {
        // font-size: 18px;
        // font-weight: bold;
      }

      .info {
        margin-left: 0;
        margin-right: 0;
      }

      md-icon {
        // color: white;
      }
    }

    .simCard-details {
      padding: 16px;
      height: auto;

      .info {
        margin-top: 5px;
        font-size: 14px;

        .notes {
          font-style: italic
        }
      }
    }
    
    .note {
      font-style: italic;
    }

    p {
      margin: 0;
    }
  }
}

@media print {

  .sidenav-simCards-view {
    position: absolute;
    top: 0;
    left: 0;

    max-width: 100%;
    width: 100%;

    transform: translate(0, 0);

    overflow: visible;

    .simCards-view {
      max-width: 100%;
      height: 100%;

      overflow: visible;

      .content-simCards-view {
        .md-subheader {
          position: initial !important;

          margin: 12px 0;
        }

        table {
          max-width: 60%;

          tr,
          th,
          td {
            text-align: center !important;  
          }

          tbody {

            td {
              padding: 0 12px 0 0 !important;
            }
          }
        }
      }
    }
  }
}