.dashboard {
	width: 100%;
  height: 100%;
  position: relative;

  &__map {
		width: 100%;
		height: 100%;
		position: relative;
		z-index: 0;

    .centroid-marker-info-window {

      .centroid-name,
      .centroid-address {
        margin: 0;
      }

      .centroid-address {
        font-size: 13px;
      }

      .div-button {

        button {
          margin-bottom: 0;
        }
      }
    }
	}

  #num {
    border : 1px;
    border-color  : black;
    font-size : 25px;
    font-weight : bolder;
    position: absolute;
    left: 0;
    top: 55%;
    height: 200px;
    width: 100%;
    text-align: center;
    z-index: 100;
  }

	&__infobutton {
	  bottom: 85px;
	  right: 0;
	  line-height: 35px !important;
	  width: 35px !important;
	  height: 35px !important;
	  position: absolute;
		font-size: 1.5em;
	}

	&__labels {
		font-family: "Verdana", "Arial", sans-serif;
		font-size: 1em;
		font-weight: bold;
		text-align: center;
		width: 40px;
		white-space: nowrap;
		line-height: 11px;

		&__space {
		  height: 6px;
		}

    .dashboard-organization-name.name-length-6 {
      font-size: 9.5px;
    }
	}

  &__button {
    position: fixed;
    bottom: 20px;
    right: 50px;

    @include prefix(transition, right .3s ease-out);

    &.right-side-nav-open {
      right: 320px;
    }
  }
}

@media (max-width: $mobileWidthLayout) {
  .dashboard__panel__card {
    padding: 0;
  }
}

@media print {
  .dashboard {
    overflow: visible !important;

    * {
      -webkit-transition: none !important;
      transition: none !important;
    }

    &__map {
      display: none;
    }
  }
}