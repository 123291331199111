.order-by-directive {

  button {
    margin-left: 0 !important;

    md-icon {
      @include prefix(transform, rotateX(180deg));
      @include prefix(transition, transform .3s);

      &.descending {
        @include prefix(transform, rotate3d(0, 0, 0, 0));
      }
    }
  }
}