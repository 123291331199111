$header-centroids-list-heigth: 65px;

#centroids-report {
  .header-centroids-list {
    height: $header-centroids-list-heigth;
    min-height: $header-centroids-list-heigth !important;
    max-height: $header-centroids-list-heigth;

    padding: 8px 12px;

    border-bottom: 1px solid rgba(0,0,0,.12);

    .div-total {

      > md-icon {
        margin-left: 0;
        margin-right: 5px;
      }
    }

    .div-list-controls {

      .order-by-directive {
        max-width: 180px;

        margin-right: 5px;
      }
    }
  }

  .centroids-list {
    height: 100%;

    padding-bottom: 36px;

    border-left: 1px solid rgba(0, 0, 0, 0.12);

    overflow-x: hidden;

    p {
      margin: 0;
    }

    [kb-item] {
      &.kb-active {
        background: #EEEEEE;
      }
    }

    .div-centroids {
      width: 100%;
      height: auto;

      padding: 6px 12px;
      display: block;
    }

    .centroid-item {
      position: relative;
      height: auto !important;

      padding: 16px;
      margin-bottom: 15px;

      text-transform: none;

      cursor: pointer;

      .avatar {
        font-size: 14px;

        md-icon {
          color: #FFFFFF;
        }
      }

      &.completed {
        background: #EEEEEE;

        .title,
        .notes {
          color: rgba(0, 0, 0, 0.54);
        }
      }

      &.selected {
        background: #FFF8E1;
      }

      .info {
        margin: 0 16px 0 8px;

        .title {
          font-size: 15px;
          font-weight: 500;
        }

        .secondary-title {
          margin-left: 5px;

          font-size: 12px !important;
        }

        md-icon {
          margin: 0;

          margin-right: 3px;
        }
      }

      .secondary-text {
        md-icon {
          margin: 0;

          margin-right: 3px;

          vertical-align: text-top;
        }
      }

      .div-centroid-totals {
        max-width: 400px;
        margin: 0 16px 0 20px;

        md-icon {
          margin-top: 3px;
        }

        .div-centroid-total {
          width: 200px;
        }
      }
    }

    .week-tracker-readings {
      position: relative;

      min-height: 260px;
      width: 100%;

      > div {
        width: 100%;
      }

      .md-subheader-content {
        display: flex;
        justify-content: space-between;
      }
    }

    .div-day-events {
      max-width: 100%;
      width: 100%;

      .nv-noData {
        font-size: 16px !important;
      }
    }
  }
}

@media screen and (width: 1920px) {
  #centroids-report {
    .centroids-list {
      padding: 0 70px;
    }
  }
}

@media print {
  #centroids-report {

    .header-centroids-list {
      height: 150px;
      min-height: 150px !important;
      max-height: 150px;

      flex-direction: column !important;
      justify-content: flex-start !important;
      align-items: stretch !important;

      .totals {
        flex-direction: row !important;
        margin-top: 5px;
      }
    }
    .centroids-list {
      .div-centroids {
        display: block !important;
        position: relative !important;
      }

      .div-day-events {
        display: block !important;
      }

      .centroid-item {
        box-shadow: none;
        max-width: 100%;
        min-width: 100%;
        width: 100%;
        position: relative;
        display: block;
        page-break-inside: avoid;
      }
    }

    table {
      max-width: 100%;

      tr,
      th,
      td {
        text-align: center !important;
        padding: 5px;
      }

      tbody,tfoot {

        td {
          padding: 0 12px 0 0 !important;
        }
      }
    }

    .print-group {
      position: relative;
      display: block;
    }

    .print-info {
      position: relative;
      display: block !important;
      float: right;
    }

    .centroids-table {
      display: block !important;
      max-width: 305px !important;
      min-width: 305px !important;
      width: 305px !important;
      margin-bottom: 35px;
      float: left;
    }
  }
}