#simCards {
  .header-simCards-list {
    height: 64px;
    min-height: 64px !important;
    max-height: 64px;

    padding: 8px 24px;

    border-bottom: 1px solid rgba(0,0,0,.12);

    .div-total {

      > md-icon {
        margin-left: 0;
        margin-right: 5px;
      }
    }
  }

  .simCards-list {
    height: 100%;

    padding-bottom: 36px;

    border-left: 1px solid rgba(0, 0, 0, 0.12);

    overflow-x: hidden;

    [kb-item].kb-active{
      background: #EEEEEE;
    }

    p {
      margin: 0;
    }

    .md-subheader .md-subheader-inner {
      padding-top: 0;
    }

    .simCard-item {
      position: relative;
      height: auto !important;

      padding: 16px;

      border-bottom: 1px solid rgba(0,0,0,.08);
      text-transform: none;

      cursor: pointer;

      .avatar {
        font-size: 14px;
      }

      &.completed {
            background: #EEEEEE;

            .title,
            .notes {
                color: rgba(0, 0, 0, 0.54);
            }
        }

        &.selected {
            background: #FFF8E1;
        }

      .info {
        margin: 0 16px 0 8px;

        .title {
          font-size: 15px;
          font-weight: 500;
        }

        .secondary-title {
          margin-left: 5px;

          font-size: 12px !important;
        }
      }

      .secondary-text {
        md-icon {
          margin: 0;
        }
      }

      .note {
        font-style: italic
      }
    }
  }
}