$dialog-height-value: 600;
$dialog-height: $dialog-height-value + px;

md-dialog {

  &.trackerReadings-list {
    min-height: $dialog-height;
    max-width : 640px;
    width     : 640px;

    &--is-editing{
      min-height: $dialog-height-value + 50 + 'px';

      md-dialog-content {
        min-height: inherit;
      }
    }

    .trackerReading {
      display       : flex;
      flex-direction: column;
      flex-grow     : 1;

      &__header {
        display    : flex;
        flex-grow  : 0;
        flex-shrink: 0;
        max-height : 410px;
        min-height : 410px;
      }

      &__content {
        color    : white !important;
        flex-grow: 1;
        padding  : 0px 16px;

        .read-more-btn {
          cursor         : pointer;
          text-decoration: underline;
        }

        .title {
          font-size  : 18px;
          font-weight: bold;
        }

        md-icon {
          color: white;
        }

        textarea, input, label {
          color: white;
        }

        textarea, label, md-text {
          font-size  : 16px;
          line-height: 1.4;
        }

        md-select md-select-value span {
          color    : white;
          font-size: 16px;
        }
      }
    }

    .truncate-obs-text {
      max-height   : 50px;
      max-width    : 600px;
      overflow     : hidden;
      text-overflow: ellipsis;
      white-space  : nowrap;
    }

   	.md-subheader .md-subheader-inner {
   		padding-top: 0;
   	}

   	.header-trackerReadings-list {
      background-color: #263238;
      opacity         : 0.7;
      position        : absolute;
      z-index         : 999999;

      .total-trackerReadings-list {
        max-height: 25px;
      }

      md-icon {
        color : #fff;
        margin: 0;
      }
   	}

    .div-floating-list-button {
      bottom: 12px;
    }

    .pointer {
      cursor: pointer;
    }

    md-dialog-content {
      position  : relative;

      min-height: $dialog-height;

      display   : flex;
      overflow-x: hidden;

      padding   : 0 !important;

      .div-event-img {
        min-height: 265px;
        max-height: 265px;

        max-width : 300px;

        z-index   : 3;

        .div-wrapper-event-img {
          background-color: #fff;
          max-width       : 100%;
          z-index         : 1;

          .div-img {
            position: relative;

            .event-img {
              width  : 100%;
              z-index: 2;

              .ng-image-gallery-modal {
                height: 225px;

                .galleria-image {
                  margin: 0;
                }
              }
            }

            &:hover {
              .event-img-png {
                display: block;
                z-index: 2;
              }
            }

            .event-img-png {
              display : none;
              height  : 97%;
              left    : 0;
              position: absolute;
              top     : 0;
              width   : 100%;

              img {
                box-shadow: none;
              }
            }
          }
        }
      }

      .event-map-img {
        min-height: 600px;
        transition: 0.4s;

        &.disabled {
          opacity: 0.3;
        }
      }

      .div-disabled-map {
        background-color: white;
        top             : 0;
        height          : 410px;
        opacity         : .8;
        position        : absolute;
        right           : 0;
        transition      : 0.3s;
        width           : 100%;
        z-index         : 2;

        @include prefix(transition, all .3s ease-out);

        &.loading {
          margin-bottom: -100px;
        }

        > div {
          padding   : 12px;
          position  : absolute;
          right     : 70px;
          text-align: center;
          top       : 215px;

          .show-map-message {
            .md-icon, span {
              transition: 0.4s;
            }
          }
        }
      }

      .div-disabled-map:hover {
        > div {
          .show-map-message {
            md-icon {
              color: rgb(230, 132, 14) !important;
            }
            span {
              color: #262626 !important;
            }
          }
        }
      }

      .event-infos {
        margin-top: auto;
      }

      .list-item {
        color      : white;
        font-size  : 14px;
        max-height : 190px;
        min-height : 190px;
        padding-top: 12px;
        width      : 100%;
        z-index    : 3;

        label {
          font-size: 18px !important;
        }

        label, textarea {
          color: white;
        }

        .title {
          font-size  : 18px;
          font-weight: bold;

          .event-edit-button {
            color       : white;
            font-size   : 22px;
            margin-left : 0;
            padding-left: 0;
          }
        }

        .info {
          margin-left : 0;
          margin-right: 0;
        }

        .div-event-buttons {
          button {
            margin-bottom: 0;
            margin-top   : 4px;
          }
        }

        md-icon {
          color: white;
        }
      }

      md-input-container {
        margin: 0;
      }

      p {
        margin: 0;
      }
    }

    md-dialog-actions {
      position: relative;
      overflow-y: hidden;
      overflow-x: auto;
      background-color: rgba(0, 0, 0, 0.03);
    }

  }

  @media screen and (max-width: 576px) {
    &.trackerReadings-list {
      md-dialog-content {
        min-height: 730px;

        .div-disabled-map {
          > div {
            top: 120px;
          }
        }
      }
    }
  }

}