$background-color: #394b6d;
$search-height: 45px;

#allocations-pro-data-report {

  background-color: rgb(245, 245, 245);

  .detail-resize {
    width: 72%;
  }

  .main-sidenav {

    &.open {
      z-index: 60;
    }
  }

  md-sidenav {
    max-width: 380px;

    width: 380px;

    background-color: rgb(250, 250, 250);

    .header {
      color: #FFFFFF;
      background-color: $background-color;

      .logo {
        padding-top: 27px;

        .logo-icon {
          margin: 0 16px 0 0;

          md-icon {
            color: #FFFFFF;
          }
        }

        .logo-text {
          font-size: 24px;
        }
      }
    }

    md-content {

      .item {
        cursor: pointer;

        &.selected {
          background-color: rgba(0, 0, 0, 0.06);
        }
      }
    }
  }

  md-fab-speed-dial md-fab-actions{
    .md-fab-action-item {
      button {
        span {
          font-size: 12px;
        }
      }
    }
  }

  .center {

    .header {
      height: 80px;
      min-height: 80px;
      max-height: 136px;

      padding: 12px;
      padding-left: 0;

      background-color: $background-color;
      color: #FFFFFF;

      .search-wrapper {
        background: #fff;
        
        .div-search {
          height: $search-height;
          line-height: $search-height;
          padding: 10px;

          .input-search {
            height: $search-height;
            
            padding-left: 16px;
            margin: 0;

            border-radius: 0;
            border: none;

            color: rgba(0,0,0,.54);

            appearance: none;
            -moz-appearance: none;
            -webkit-appearance: none;
          }
        }
      }
      .print-wrapper {
        margin: 150px 0 0 10px;

        .div-icon-loading {
          md-progress-circular.md-default-theme path, 
          md-progress-circular path {
            stroke: white;
          }
        }
        
      }

      .print-btn {
        md-icon{
          color: #FFFFFF;
        }
      }

      .print-btn:disabled {
        md-icon{
          color: gray;
        }
      }
      
    }
  }
}

@media screen and (max-width: $layout-breakpoint-sm) {
  #allocations-pro-data-report {

    .header {

      .div-search {
        border-left: 1px solid rgba(0, 0, 0, 0.12);
      }
    }

    .center {

      .header {
        padding-left: 16px;

        .print-btn {
          md-icon{
            color: #FFFFFF;
          }
        }
      }
    }
  }
}

@media print {
  * {
    -webkit-transition: none !important;
    transition: none !important;
  }

  .content-card {
    -webkit-print-color-adjust: economy !important;
  }
  
  #allocations-pro-data-report {
    -webkit-print-color-adjust: economy !important;
    position: absolute;
    display: block;
    overflow: visible !important;

    md-content {
      overflow: visible !important;
    }

    .md-sidenav-right{
      max-width: 100%;
      width: 100%;    
      display: block;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
    }
  }
}