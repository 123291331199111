$background-color: #394b6d;
$search-height: 45px;

#allocationMonitrips-report {

  background-color: rgb(245, 245, 245);

  .detail-resize {
    width: 72%;
  }

  .main-sidenav {

    &.open {
      z-index: 60;
    }
  }

  md-sidenav {
    max-width: 380px;

    width: 380px;

    background-color: rgb(250, 250, 250);

    .header {
      color: #FFFFFF;
      background-color: $background-color;

      .logo {
        padding-top: 27px;

        .logo-icon {
          margin: 0 16px 0 0;

          md-icon {
            color: #FFFFFF;
          }
        }

        .logo-text {
          font-size: 24px;
        }
      }
    }

    md-content {

      .item {
        cursor: pointer;

        &.selected {
          background-color: rgba(0, 0, 0, 0.06);
        }
      }
    }

    .div-add-button {
      padding: 0 32px 6px;
    }
  }

  .center {

    .header {
      height: 80px;
      min-height: 80px;
      max-height: 136px;

      padding: 12px;
      padding-left: 0;

      background-color: $background-color;
      color: #FFFFFF;

      .search-wrapper {
        background: #fff;

        .div-search {
          height: $search-height;
          line-height: $search-height;
          padding: 10px;

          .input-search {
            height: $search-height;

            padding-left: 16px;
            margin: 0;

            border-radius: 0;
            border: none;

            color: rgba(0,0,0,.54);

            appearance: none;
            -moz-appearance: none;
            -webkit-appearance: none;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $layout-breakpoint-sm) {
  #allocationMonitrips-report {

    .header {

      .div-search {
        border-left: 1px solid rgba(0, 0, 0, 0.12);
      }
    }

    .center {

      .header {
        padding-left: 16px;
      }
    }
  }
}

@media print {
  #allocationMonitrips-report {
    position: absolute;
    display: block;
    overflow: visible !important;

    md-content {
      overflow: visible !important;
    }

    .md-sidenav-right{
      max-width: 100%;
      width: 100%;
      display: block;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
    }
  }
}