.service-report {
  position: relative;
  width: 100%;
  height: 100%;

  background-color: rgb(245, 245, 245);

  > * {
    width: 100%;
    height: 100%;
  }

  .service-filters {
    position: absolute !important;
    left: 12px;
    top: 12px;

    max-height: 300px;
    min-width: 220px;

    padding: 4px 0;

    z-index: 60;

    background-color: rgb(245, 245, 245);

    @include prefix(transition, all .3s ease-out);

    &:not(.filters-open) {
      min-width: 0;
    }

    .sidenav {
      width: 100%;

      padding: 22px 16px 12px;

      background: 0 0;

      overflow: hidden;

      md-input-container {
        max-height: 35px;

        margin: 16px 0;
      }

      .input-date {
        label {
          right: 45px !important;
        }

        md-datepicker {

          .md-datepicker-button {
            max-width: 35px;

            margin-left: 0;
            padding-left: 0;
            margin-right: 0;
            padding-right: 0;

            md-icon {
              margin-left: 0;
              margin-right: 0;
            }
          }

          .md-datepicker-input-container {
            margin-left: 0;

            border-bottom-width: 0;
          }
        }
      }

      .onlyOccurrences-input-container {
        margin-top: 4px;
        margin-bottom: 12px;

        md-switch {
          margin: 0;
        }
      }
    }

    .button-toggle-sidenav {
      z-index: 61;
    }
  }

  .service-map-div {
    position: relative;

    box-shadow: 0 4px 5px -2px rgba(0,0,0,.2), 0 7px 10px 1px rgba(0,0,0,.14), 0 2px 16px 1px rgba(0,0,0,.12);

    z-index: 3;

    .service-map {
      position: relative;

      width: 100%;
      height: 100%;

      z-index: 0;
    }

    .div-button-toggle-view-sidenav {
      position: fixed;
      top: 50%;
      right: 0;

      padding: 4px 0;

      cursor: pointer;

      z-index: 3;
      background-color: rgb(255, 255, 255);

      &:hover {
        background-color: #f1f0f1;
      }
    }
  }

  .service-sidebar {

    max-width: 380px;

    .service-view {
      > .md-subheader,
      > md-toolbar {
        background-color: #263238;
        color: #fff;

        * {
          color: #fff;
        }

        .info-allocation {
          max-height: 25px;

          margin-bottom: 5px;

          font-size: 14px;

          @extend .text-truncate;
        }

        md-icon {
          margin-left: 0;
          margin-right: 2px;
        }

        md-input-container {
          margin-bottom: 0;

          > * {
            color: #fff !important;
          }

          input {
            border-bottom-color: #fff !important;
          }
        }
      }
    }

    .service-occurrences-list {

      .md-list-item-text {

        > * {
          font-size: 16px;
        }
      }

      .md-secundary {

        span {
          line-height: 1.3em;
        }
      }
    }

    .div-allocation-people-status {
      padding: 6px;

      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      flex-flow: row wrap;

      > div {
        position: relative;

        min-width: 75px;
        max-width: 85px;
        width: 75px;

        min-height: 92px;
        max-height: 92px;
        height: 92px;

        padding: 2px 0;
        margin: 2px;

        text-align: center;
        font-weight: bold;

        .avatar {
          margin: 0;

          font-weight: bold;
        }

        .allocation-totals {
          max-height: 20px;

          flex: 1;

          padding: 6px;

          line-height: 1em;
        }

        .div-loading {
          top: -10px;
        }
      }
    }

    .timeline-events-list {

      .event-item {
        padding: 4px 16px 8px 16px;

        border: none !important;

        .info {
          margin-right: 0;
        }

        .title {
          margin-bottom: 3px;

          font-size: 16px;

          font-weight: bold;
        }

        .notes {
          padding-top: 3px;
          font-size: 14px;

          md-icon {
            margin-left: 0;
            margin-right: 3px;
          }
        }

        .total-balancing {

          text-overflow: clip;
          overflow: visible;

          span {
            &:before {
              content: '(';
            }

            &:after {
              content: ')';
            }

            .balancing-positive {
              &:before {
                content: ' +';
              }
            }
          }
        }

        .secondary-text {

          &.time {
            margin-top: 3px
          }

          md-icon {
            margin-left: 0;
            margin-right: 3px;
          }
        }
      }

      .md-list-item-inner {
        padding: 6px 0;
      }

      .avatar {
        margin-right: 5px;

        font-size: 17px !important;

        &.occurrence-event {
          font-size: 24px !important;
        }
      }
    }

    .md-subheader-inner {
      padding-bottom: 8px;
    }

    md-list-item {

      .md-list-item-inner {
        padding: 8px 0;

        z-index: 1;
      }

      .md-list-item-text {

        .title {
          @extend .text-semibold;
        }
      }
    }

    p {
      margin: 0;
    }
  }

  .centroid-marker-info-window {

    h3, h4, p {
      margin: 0 !important;
    }
  }
}

@media screen and (max-width: 459px) {
  .service-report {

    .service-sidebar {
      max-width: 100%;
      width: 100%;
    }
  }
}

@media screen and (max-width: $layout-breakpoint-xs) {
  .service-report {

    .service-filters {

      &.filters-open {
        height: 100%;
      }
    }
  }
}

@media screen and (max-width: $layout-breakpoint-sm) {
  .service-report {

    .service-filters {

      &.filters-open {
        max-height: 310px;

        z-index: 63;
      }

      .sidenav {
        padding-top: 0;
      }
    }
  }
}

@media print {
  .service-report {

    .service-sidebar {
      max-width: 100%;
      height: 100%;

      width: 100%;

      overflow: visible !important;

      .service-view,
      md-content {
        overflow: visible !important;
      }

      md-toolbar {
        color: #000000;
        background-color: #fff;

        md-icon {
          margin-left: 0;
          margin-right: 2px;

          color: #000000;
        }
      }

      .content {
        display: block;

        .md-subheader {
          position: initial !important;

          margin: 12px 0;
        }
      }
    }
  }
}