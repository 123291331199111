.centroid-view {
  max-width: 720px;
  min-height: 420px;
  width: 500px;

 	.md-subheader .md-subheader-inner {
    padding-top: 8px;
 	}

  .header-centroid-view {
    padding-bottom: 10px;

    background-color: #263238 !important;
    color: #fff !important;

    .md-toolbar-tools-top {
      min-height: 50px;
      height: 50px;

      .title {
        padding-left: 0;
      }
    }

    .info-centroid {
      font-size: 14px;

      // @extend .text-nowrap;
    }

    md-icon {
      margin-left: 0;
      margin-right: 5px;

      color: #fff;
    }
  }

  .div-floating-list-button {
    bottom: 12px;
  }

  .div-centroid-img {
    
    .centroid-img {

      z-index: 2;

      .ng-image-gallery-modal {
        height: 225px;

        img {
          width: 100%;
        }
      }
    }

    .centroid-map-img {
      position: absolute;
      right: 0;
    }
  }

  md-content {
    overflow-x: hidden;

    .day-tracker-readings,
    .week-tracker-readings {
      position: relative;

      min-height: 260px;
      width: 100%;

      > div {
        width: 100%;
      }
    }

    .list-item {
      padding-top: 12px;

      font-size: 14px;

      color: white;

      .title {
        font-size: 18px;
        font-weight: bold;
      }

      .info {
        margin-left: 0;
        margin-right: 0;
      }

      md-icon {
        color: white;
      }
    }

    .div-day-events {
      width: 100%;
      max-width: 350px;

      margin-left: -25px;

      .nv-noData {
        font-size: 16px !important;
      }
    }

    p {
      margin: 0;
    }
  }
}

@media print {

  .sidenav-centroid-view {
    position: absolute;
    top: 0;
    left: 0;

    max-width: 100%;
    width: 100%;

    transform: translate(0, 0);

    overflow: visible;

    .centroid-view {
      max-width: 100%;
      height: 100%;

      overflow: visible;

      .content-centroid-view {
        .md-subheader {
          position: initial !important;

          margin: 12px 0;
        }

        table {
          max-width: 55%;

          tr,
          th,
          td {
            text-align: center !important;  
          }

          tbody {

            td {
              padding: 0 12px 0 0 !important;
            }
          }
        }

        .div-day-events {
          width: 100%;

          padding: 0 12px;

          margin-left: 0;
        }
      }
    }
  }
}