.login {
  &__card {
    background: white;
    border: solid 1px $ebb;
    border-radius: 5px;
    box-shadow: 0 0 5px $alto;
    width: 25em;
    height: 27em;
    padding: 2em;
    box-sizing: border-box;
    margin: auto;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;

    &__center {
      text-align: center;
    }

    &__logo {
      text-align: center;
      margin-bottom: 2em;

      &__img {
        width: 50%;
      }
    }
  }
}
