$background-color: #394b6d;
$search-height: 45px;

#terminals-dashboard {

  background-color: rgb(245, 245, 245);

  .main-sidenav {

    &.open {
      z-index: 60;
    }
  }

  md-sidenav {

    background-color: rgb(250, 250, 250);

    .header {
      color: #FFFFFF;
      background-color: $background-color;

      .logo {
        padding-top: 27px;


        .logo-icon {
          margin: 0 16px 0 0;

          md-icon {
            color: #FFFFFF;
          }
        }

        .logo-text {
          font-size: 24px;
        }
      }
    }

    md-content {

      .item {
        cursor: pointer;

        &.selected {
          background-color: rgba(0, 0, 0, 0.06);
        }
      }
    }

    .content {
      padding: 12px 0;
    }

    .div-add-button {
      padding: 0 32px 6px;
    }
  }

  md-tab-content {
    overflow: hidden;
  }

  md-tab-content > div {
    overflow: hidden;

    .tabs-list {
      a {
        color: #1565C0;
      }
    }
  }

  md-ink-bar {
    color: rgb(63,81,181);
    background: rgb(63,81,181);
  }
  .center {

    .header {
      height: 80px;
      min-height: 80px;
      max-height: 80px;

      padding: 12px;

      background-color: $background-color;
      color: #FFFFFF;

      .search-wrapper {
        background: #fff;
        
        .div-search {
          height: $search-height;
          line-height: $search-height;
          padding: 10px;

          .input-search {
              height: $search-height;
              
              padding-left: 16px;
              margin: 0;

              border-radius: 0;
              border: none;
              
              color: rgba(0,0,0,.54);

              appearance: none;
              -moz-appearance: none;
              -webkit-appearance: none;
          }
        }
      }
    }
  }

  .div-icon-loading {
    margin: 4px 0 0 8px;
  }

  .terminal-item {
    margin: 6px 8px;
  }

  .terminals-status-list {
    height: 100%;

    padding-bottom: 36px;

    border-left: 1px solid rgba(0, 0, 0, 0.12);

    overflow-x: hidden;

    p {
      margin: 0;
    }

    .div-terminals {
      width: 100%;
      height: auto;

      padding: 6px 0 6px 12px;

      // &:not(:last-of-type) {
      //   border-bottom: solid 1px rgba(0, 0, 0, .08);
      // }

      @include prefix(flex-flow, row wrap);
    }
  }
  
  .paginationEnd {
    font-size: 17px;
    padding: 10px;
  }

  .load-more {
    width: 100%;

    &:hover {
      background-color: rgba(0, 0, 0, 0.26);
    }
  }

  md-tab-item[tabindex="0"].camera ~ md-ink-bar {
    background-color:  #1565C0 !important;
  }

  md-tab-item[tabindex="0"].terminalsOk ~ md-ink-bar {
    background-color: #4CAF50 !important;
  }

  md-tab-item[tabindex="0"].terminalsCriticals ~ md-ink-bar {
    background-color: red !important;
  }

  md-tab-item[tabindex="0"].terminalsOff ~ md-ink-bar {
    background-color: #616161 !important;
  }
}

@media screen and (max-width: $layout-breakpoint-md) {
  #terminals-dashboard {

    .header {

      .div-search {
        border-left: 1px solid rgba(0, 0, 0, 0.12);
      }
    }

    .center {

      .header {
        padding-left: 16px;
      }
    }
  }
}

@media screen and (width: 1920px) {
  #terminals-list-dashboard {
    .terminals-list {
      padding: 0 70px;
    }

    .terminals-status-list {
      padding: 0 190px;
    }
  }
}

@media screen and (width: 1366px) {
  #terminals-list-dashboard {
    .terminals-list {
      padding: 0 80px;
    }

    .terminals-status-list {
      padding: 0 110px;
    }
  }
}

@media screen and (width: 1440px) {
  #terminals-list-dashboard {
    .terminals-list {
      padding: 0 120px;
    }

    .terminals-status-list {
      padding: 0 150px;
    }
  }
}

@media screen and (width: 1280px) {
  #terminals-list-dashboard {
    .terminals-list {
      padding: 0 40px;
    }

    .terminals-status-list {
      padding: 0 65px;
    }
  }
}
