.line-visualizer {
  padding: 10px;
  box-sizing: border-box;
  user-select: none;
  overflow: hidden;
  display: flex;

  &__map {
    width: 600px;
    height: 300px;
  }
}
