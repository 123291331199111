@media print {
  .timeline-div {
    page-break-before: always !important;
    page-break-after: always !important;
    page-break-inside: avoid !important;

    * {
      page-break-inside: avoid !important;
    }
  }
}