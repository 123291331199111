.header-terminals-list {
  height: 64px;
  min-height: 64px !important;
  max-height: 64px;

  padding: 8px 24px;

  border-bottom: 1px solid rgba(0,0,0,.12);

  md-checkbox {
    margin: 0;

    &.md-checked {
      .md-ink-ripple {
        color: rgb(63,81,181) !important;
      }

      .md-icon {
        background-color: rgb(63,81,181) !important;
      }
    }
  }

  .div-total {

    > md-icon {
      margin-left: 0;
      margin-right: 5px;
    }

    .div-icon-loading {
      max-width: 100px;
    }
  }

  .div-sub-total {
    flex-flow: row wrap;
    margin-right: 10px;
  }
}

.command-actions md-menu-item {
  width: 100% !important;
}

.terminals-list-sections {
  height: 100%;

  padding-bottom: 36px;

  border-left: 1px solid rgba(0, 0, 0, 0.12);

  overflow-x: hidden;

  p {
    margin: 0;
  }

  .md-subheader .md-subheader-inner {
    padding-top: 0;
  }

  .div-terminals {
    width: 100%;
    height: auto;

    padding: 6px 0;

    .terminal-item {
      position: relative;
      height: auto !important;

      padding: 12px;

      border-bottom: 1px solid rgba(0,0,0,.08);
      text-transform: none;

      cursor: pointer;

      .avatar {
        font-size: 14px;
      }

      .info {
        margin: 0 16px 0 8px;

        .title {
          font-size: 15px;
          font-weight: 500;
        }

        md-icon {
          margin: 0;

          margin-right: 3px;
        }

        .secondary-title {
          margin-left: 5px;

          font-size: 12px !important;
        }
      }

      .secondary-text {
        md-icon {
          margin: 0;

          margin-right: 3px;
        }
      }

      md-input-container {
        margin: 30px 0;
      }

      md-checkbox {
        margin: 0;

        &.md-checked {
          .md-ink-ripple {
            color: rgb(63,81,181) !important;
          }

          .md-icon {
            background-color: rgb(63,81,181) !important;
          }
        }
      }


    }

    .scroll-load {
      padding: 15px;
    }

    @include prefix(flex-flow, row wrap);
  }
}


@media screen and (max-width: 680px) {
  .header-terminals-list {
    // height: 120px;
    // min-height: 120px !important;
    // max-height: 120px;

    // padding: 8px 24px;

    // flex-direction: column !important;

    // border-bottom: 1px solid rgba(0,0,0,.12);

    // .div-total {

    //   > md-icon {
    //     margin-left: 0;
    //     margin-right: 5px;
    //   }
    // }

    .terminal-actions-menu-text {
      display: none;
    }

    md-menu-bar {
      padding: 0px;

      button {
        min-width: 30px !important;
      }
    }
  }
}