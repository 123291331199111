md-dialog {

  &.branchOrganizationsSecundario-list {
    max-width: 720px;
    width: 720px;

    overflow: hidden;

    md-toolbar {
     background-color: #394b6d;
    }

    md-dialog-content {
      position: relative;

      min-height: 200px;

      display: block;
    }

    md-toolbar {

      .title {
        padding: 18px 0 0;

        font-size: 17px;
      }

      md-input-container {
        margin-bottom: 0;

        > * {
          color: #fff !important;
        }

        input {
          border-bottom-color: #fff !important;
        }
      }
    }

    md-dialog-actions {
      position: relative;
      overflow-y: hidden;
      overflow-x: auto;
      justify-content: space-between;
      background-color: rgba(0, 0, 0, 0.03);
      border-top: 1px solid rgba(0, 0, 0, 0.12);
    }
  }
}