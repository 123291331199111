md-dialog {

  &.line-csv-import {
    overflow: hidden;

    md-toolbar {
      background-color: #394b6d;

      .title {
        font-size: 17px;
      }
    }

    md-dialog-content {
      display: block;
      position: relative;

      padding: 16px;

      .line-csv-description {

        .title {
          margin: 0;
          line-height: 1.4em;

          font-weight: 400;
        }

        .csv-columns {
          padding-top: 18px;

          .list-csv-columns {
            margin-left: 25px;

            font-size: 13px;
          }
        }
      }

      md-input-container {

        md-icon {
          color: rgba(0,0,0,0.54);
        }

        > label {
          padding-left: 0 !important;
        }
      }
    }

    md-dialog-actions {
      position: relative;
      overflow-y: hidden;

      overflow-x: auto;

      border-top: 1px solid rgba(0, 0, 0, 0.12);

      background-color: rgba(0, 0, 0, 0.03);
    }
  }
}

@media screen and (max-width: $layout-breakpoint-xs) {

  md-dialog {

    &.line-csv-import {
      width: 100%;

      .div-input-lines {

        > :not(:first-child) {
          margin-top: 0;
        }
      }

      md-input-container {
        max-height: 70px;
      }
    }

  }
}

@media screen and (min-width: $layout-breakpoint-xs) {

  md-dialog {

    &.line-csv-import {
      min-width: 600px;

      md-input-container {

        &:not(:first-child) {
          margin-left: 10px;
        }
      }

      md-dialog-content {

        .div-line-inputs {
          max-height: 70px;
        }
      }
    }

  }
}

@media screen and (max-width: $layout-breakpoint-md) {

  md-dialog {

    &.line-csv-import {
      width: 80%;
    }
  }
}

@media screen and (min-width: $layout-breakpoint-sm) {

  md-dialog {
    max-width: 720px;

    &.line-csv-import {
      width: 90%;
    }
  }
}

@media screen and (max-width: $layout-breakpoint-sm) {

  md-dialog {

    &.line-csv-import {
      width: 90%;
    }
  }
}