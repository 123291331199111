$background-color: #394b6d;

#installation-branch {

  background-color: rgb(245, 245, 245);

  .center {

    .header {
      height: 110px;
      min-height: 100px;
      max-height: 136px;

      padding: 12px;

      background-color: $background-color;
      color: #FFFFFF;

      .search-wrapper {
        background: #fff;
        
        .div-search {
          height: 56px;
          min-height: 56px;
          padding: 18px;

          .input-search {
              height: 56px;
              
              padding-left: 16px;
              margin: 0;

              border-radius: 0;
              border: none;
              
              color: rgba(0,0,0,.54);

              appearance: none;
              -moz-appearance: none;
              -webkit-appearance: none;
          }
        }
      }
    }

    .header-branches-list {
      height: 65px;
      min-height: 65px !important;
      max-height: 65px;

      padding: 8px 24px;

      flex-direction: column !important;

      display: flex;

      border-bottom: 1px solid rgba(0,0,0,.12);

      .div-total {

        > md-icon {
          margin-left: 0;
          margin-right: 5px;
        }
      }


      .expandable-search {
        .mdl-layout {
          align-items: center;
          justify-content: center;
        }
  
        .mdl-layout__content {
          padding: 24px;
          flex: none;
        }
  
        .mdl-button--icon {
          top: 0;
        }

        .mdl-textfield--expandable {
          padding: 0;

          .mdl-textfield__expandable-holder {
            .mdl-textfield__input {
              font-size: 1.3rem;
            }
          }
        }
  
        .mdl-textfield--expandable.is-focused,.is-dirty {
          // .pagination-directive {
          //   display: none;
          // }
          min-width: 300px;
          max-width: 300px;

          .mdl-textfield__expandable-holder {
            .mdl-textfield__input {
              min-width: 300px;
              max-width: 300px;
            }
          }
        }
      }
    }

    .branches-list {
      height: 100%;

      padding-bottom: 50px;

      border-left: 1px solid rgba(0, 0, 0, 0.12);

      overflow-x: hidden;

      p {
        margin: 0;
      }

      .md-subheader .md-subheader-inner {
        padding-top: 0;
      }
    }

    .branch-item {
      position: relative;
      height: auto !important;

      padding: 16px;
      
      border-bottom: 1px solid rgba(0,0,0,.08);
      text-transform: none;
      
      cursor: pointer;

      .avatar {
        font-size: 14px;
      }

      .info {
        margin: 0 16px 0 8px;

        .title {
          font-size: 15px;
          font-weight: 500;
        }

        .secondary-title {
          margin-left: 5px;

          font-size: 12px !important;
        }
      }

      .secondary-text {
        md-icon {
          margin: 0;
        }
      }

      .selected {
        background-color: rgba(0, 0, 0, .12);
      }
    }
  }
}

@media screen and (max-width: $layout-breakpoint-sm) {
  #installation-branch {

    .header {

      .div-search {
        border-left: 1px solid rgba(0, 0, 0, 0.12);
      }
    }

    .center {

      .header {
        padding-left: 16px;
      }
    }
  }
}
.terminals-list {
  height: 100%;

  // padding-bottom: 36px;

  border-left: 1px solid rgba(0, 0, 0, 0.12);

  overflow-x: hidden;

  p {
    margin: 0;
  }
}

@media screen and (max-width: 450px) {
  .header-branches-list {
    height: 120px !important;
    min-height: 120px !important;
    max-height: 120px;
    display: block !important;


    padding: 8px 15px !important;

    border-bottom: 1px solid rgba(0,0,0,.12);

    .div-total {

      > md-icon {
        margin-left: 0;
        margin-right: 5px;
      }
    }
    
    .expandable-search {

      .mdl-button--icon {
        top: 5px !important;
      }
  
      .mdl-textfield--expandable.is-focused,.is-dirty {
        min-width: unset !important;
        max-width: unset !important;
        flex: 1;
        
        .mdl-textfield__expandable-holder {
          flex: 1;

          .mdl-textfield__input {
            padding-top: 10px !important;
            min-width: unset !important;
          }
        }
      }
    }
  }
}