$background-color: #394b6d;

md-dialog {

  &.centroid-events-graph-dialog {
    overflow: hidden;

    md-dialog-content {
      position: relative;
      min-height: 200px;
      max-height: 400px;

      padding: 16px;

      display: block;
      overflow-x: hidden;

      .div-terminal-terminals {

        .header-terminal-terminals {
          height: 46px;
          min-height: 46px;
          width: 108%;

          margin-bottom: 12px;
          margin-left: -20px;
          padding: 12px 16px;

          > span {
            padding: 12px 0 0;
          }
        }

        .div-terminal {

          .terminal-item {
            cursor: move !important;
          }
        }
      }

      .div-edit-terminal {
        padding: 0 8px;
      }
    }

    md-dialog-actions {
      position: relative;

      overflow-y: hidden;
      overflow-x: auto;

      border-top: 1px solid rgba(0, 0, 0, 0.12);

      background-color: rgba(0, 0, 0, 0.03);
    }

    .header-centroid-events-graph-dialog {
      &__date-filters {
        md-input-container {
          margin: 0;

          input,
          label,
          label::after {
            @extend .white-500-fg;
          }

          label {
            font-size: 16px;
          }

          md-datepicker {
            .md-datepicker-input-container {
              border-color: white;
            }
            .md-datepicker-expand-triangle {
              border-top-color: white !important;
            }
          }
        }
      }

      &__centroid-filter {
        padding-top: 5px;

        md-autocomplete {
          md-input-container {
            input,
            input:focus,
            label,
            label::after {
              @extend .white-500-fg;
              border-color: white;
            }
          }

          button {
            path {
              fill: white;
            }
          }
        }
      }
    }

    .content-centroid-events-graph-dialog {
      height: 100%;

      background-color: rgb(245, 245, 245);

      .center {

        .header {
          height: auto;

          padding: 12px;

          background-color: $background-color;

          color: #FFFFFF;

          .search-wrapper {
            background: #fff;

            .div-search {
              height: 56px;
              min-height: 56px;
              padding: 18px;

              .input-search {
                height: 56px;

                padding-left: 16px;
                margin: 0;

                border-radius: 0;
                border: none;

                color: rgba(0,0,0,.54);

                appearance: none;
                -moz-appearance: none;
                -webkit-appearance: none;
              }
            }
          }

          md-input-container {

            > * {
              color: #fff !important;

              &:focus {
                border-bottom-color: #fff;
              }

              &.md-input-focused {
                border-bottom-width: 1px !important;
              }
            }

            .md-errors-spacer {
              display: none;
            }
          }

          md-checkbox {
            width: 20px;

            margin-bottom: 0;
          }
        }

        .header-centroid-events-graph-dialog {
          height: 64px;
          min-height: 64px !important;
          max-height: 64px;

          padding: 8px 24px;

          border-bottom: 1px solid rgba(0,0,0,.12);

          .div-total {

            > md-icon {
              margin-left: 0;
              margin-right: 5px;
            }
          }
        }

        .terminals-list {
          height: 100%;

          padding-bottom: 36px;

          border-left: 1px solid rgba(0, 0, 0, 0.12);

          overflow-x: hidden;

          p {
            margin: 0;
          }

          .md-subheader .md-subheader-inner {
            padding-top: 0;
          }
        }

        .terminal-item {
          position: relative;
          height: auto !important;

          padding: 16px;

          border-bottom: 1px solid rgba(0,0,0,.08);
          text-transform: none;

          cursor: pointer;

          .avatar {
            font-size: 14px;
          }

          .info {
            margin: 0 16px 0 8px;

            .title {
              font-size: 15px;
              font-weight: 500;
            }

            .secondary-title {
              margin-left: 5px;

              font-size: 12px !important;
            }
          }

          .secondary-text {
            md-icon {
              margin: 0;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: $layout-breakpoint-xs) {

  md-dialog {

    &.centroid-events-graph-dialog {
      width: 100%;

      md-autocomplete {

        md-autocomplete-wrap {

          >md-input-container {
            margin-top: 0;
          }

          >button {
            top: 0 !important;
          }
        }
      }

      .div-input-terminals {

        > :not(:first-child) {
          margin-top: 0;
        }
      }

      .div-autocomplete,
      .input-date {
        max-height: 35px;
      }

      .div-autocomplete {
        padding-left: 3px;
        margin: 18px 0;

        &:first-child {
          margin-top: 0;
        }
      }

      md-input-container {
        max-height: 70px;
      }
    }
  }
}

@media screen and (min-width: $layout-breakpoint-xs) {

  md-dialog {

    &.centroid-events-graph-dialog {
      min-width: 600px;

      .div-autocomplete {

        &:not(:first-child) {
          margin-left: 10px;
        }
      }

      md-input-container {

        &:not(:first-child) {
          margin-left: 10px;
        }
      }

      md-dialog-content {

        .div-terminal-inputs {
          max-height: 70px;
        }
      }
    }
  }
}

@media screen and (max-width: $layout-breakpoint-md) {

  md-dialog {

    &.centroid-events-graph-dialog {
      width: 80%;
    }
  }
}

@media screen and (min-width: $layout-breakpoint-sm) {

  md-dialog {

    &.centroid-events-graph-dialog {
      max-width: 820px;
      width: 90%;
    }
  }
}

@media screen and (max-width: $layout-breakpoint-sm) {

  md-dialog {

    &.centroid-events-graph-dialog {
      width: 90%;

      .installation-terminals {

        .header {

          .div-search {
            border-left: 1px solid rgba(0, 0, 0, 0.12);
          }
        }

        .center {

          .header {
            padding-left: 16px;
          }
        }
      }
    }
  }
}