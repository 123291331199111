md-dialog {

  &.organization-edit {
    min-height: 200px;

    overflow: hidden;

    md-toolbar {

      .title {
        font-size: 17px;
      }
    }

    md-dialog-content {
      display: block;
      position: relative;

      padding: 16px;

      md-input-container {

        md-icon {
          color: rgba(0,0,0,0.54);
        }

        > label {
          padding-left: 0 !important;
        }
      }

      .div-autocomplete {

        &:first-child {
          padding-left: 3px;
        }

        > md-icon {
          max-width: 24px;

          padding-top: 3px;
          margin: 22px 6px 0 0;
        }
      }

      .div-gmaps {
        min-height: 350px;

        .gmaps {
          overflow: hidden;
        }
      }
    }

    md-dialog-actions {
      position: relative;
      overflow-y: hidden;

      overflow-x: auto;

      border-top: 1px solid rgba(0, 0, 0, 0.12);

      background-color: rgba(0, 0, 0, 0.03);
    }
  }
}

@media screen and (max-width: $layout-breakpoint-xs) {

  md-dialog {

    &.organization-edit {
      width: 100%;

      md-autocomplete {

        md-autocomplete-wrap {

          >md-input-container {
            margin-top: 0;
          }

          >button {
            top: 0 !important;
          }
        }
      }

      .div-autocomplete {
        max-height: 35px;
        padding-left: 3px;
        margin: 18px 0;

        &:first-child {
          margin-top: 0;
        }

        > md-icon {
          margin-top: 3px !important;
        }
      }

      .div-input-lines {

        > :not(:first-child) {
          margin-top: 0;
        }
      }

      md-input-container {
        max-height: 70px;
      }
    }

  }
}

@media screen and (min-width: $layout-breakpoint-xs) {

  md-dialog {

    &.organization-edit {
      min-width: 600px;

      .div-organization-inputs {
        max-height: 75px;
      }

      .div-autocomplete {

        &:not(:first-child) {
          margin-left: 10px;
        }
      }

      md-input-container {

        &:not(:first-child) {
          margin-left: 10px;
        }
      }

      md-dialog-content {

        .div-line-inputs {
          max-height: 70px;
        }
      }
    }

  }
}

@media screen and (max-width: $layout-breakpoint-md) {

  md-dialog {

    &.organization-edit {
      width: 80%;
    }
  }
}

@media screen and (min-width: $layout-breakpoint-sm) {

  md-dialog {

    &.organization-edit {
      max-width: 720px;
      width: 90%;
    }
  }
}

@media screen and (max-width: $layout-breakpoint-sm) {

  md-dialog {

    &.organization-edit {
      width: 90%;
    }
  }
}