#allocations-report {

  .sidenav {
    width: 220px;
    min-width: 220px;
    max-width: 220px;
    
    z-index: 2;
    background: 0 0;
    box-shadow: none;

    .header {
      height: 114px;
      min-height: 114px;
      max-height: 174px;

      padding: 24px;

      border-bottom: 1px solid rgba(0, 0, 0, 0.12);

      .logo {

        .logo-icon {
          margin: 0 16px 0 0;
        }

        .logo-text {
          font-size: 24px;
        }
      }
    }

    .content {
      padding: 24px 0;

      .item {
        cursor: pointer;

        &.selected {
          background-color: rgba(0, 0, 0, 0.06);
        }

        label {
          cursor: pointer;
        }
      }

      .select-filter {
        padding: 0 24px 0 16px;
      }

      md-icon {
        color: rgba(0, 0, 0, .56);
      }

      md-input-container {
        max-height: 30px;
      }

      md-select {
        height: 35px !important;
      }

      mdp-time-picker {

        > div {
          padding: 0 24px 0 12px;

          md-input-container {
            @include prefix(flex, 1);
          }
        }
      }
    }

    &.md-locked-open {

      .header {
        color: #FFFFFF;

        .logo {
          padding-top: 27px;

          .logo-icon {

            md-icon {
              color: #FFFFFF;
            }
          }
        }
      }
    }

    &:not(.md-locked-open) {

      .header {
        height: 150px;
        min-height: 150px;
        max-height: 150px;
        padding-top: 24px;
      }
    }
  }
}