.action-editor {
  padding: 10px;
  box-sizing: border-box;
  user-select: none;
  overflow: hidden;
  display: flex;

  &__data {
    min-width: 300px;
    margin-right: 10px;
  }

  &__save {
    text-align: right;
    margin-right: 15px;
  }
}
