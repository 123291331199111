$prefixes: (
  webkit
  moz
  ms
  o
);

$displaysFlex: (
  webkit-flex
  ms-flexbox
  webkit-box
  moz-box
  moz-flex
);

@mixin prefix($property, $value) {
  @each $prefix in $prefixes {
    #{'-' + $prefix + '-' + $property}: $value;
  }

  #{$property}: $value;
}

@mixin prefixes($declarations) {
  @each $property, $value in $declarations {
    @include prefix($property, $value);
  }
}

@mixin displayFlex {
  @each $display in $displaysFlex {
    display: #{'-' + $display};
  }

  display: flex;
}