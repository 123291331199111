.allocationMonitrips-view {
  max-width: 380px;
  min-height: 420px;

  width: 380px;

  > .md-subheader,
  > md-toolbar {
    background-color: #263238;
    color: #fff;

    .info-allocationMonitrip {
      max-height: 25px;

      margin-bottom: 5px;

      font-size: 14px;

      @extend .text-truncate;
    }

    md-icon {
      margin-left: 0;
      margin-right: 2px;

      color: #fff;
    }

    md-input-container {
      margin-bottom: 0;

      > * {
        color: #fff !important;
      }

      input {
        border-bottom-color: #fff !important;
      }
    }
  }

  .service-occurrences-list {

    .md-list-item-text {

      > * {
        font-size: 16px;
      }
    }

    .md-secundary {

      span {
        line-height: 1.3em;
      }
    }
  }

  .div-allocationMonitrip-people-status {
    padding: 6px;

    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-flow: row wrap;

    > div {
      position: relative;

      min-width: 75px;
      max-width: 75px;
      width: 75px;

      min-height: 82px;
      max-height: 82px;
      height: 82px;

      padding: 2px 0;
      margin: 2px;

      text-align: center;
      font-weight: bold;

      .avatar {
        margin: 0;

        font-weight: bold;
      }

      .name-allocationMonitrip-people {
        flex: 1;

        padding: 6px;

        line-height: 1em;
      }

      .div-loading {
        top: -10px;
      }
    }
  }

  .div-timeline {
    min-height: 300px;

    .timeline-events-list {
      min-height: 300px;

      .event-item {
        padding: 4px 16px 8px 16px;

        border: none !important;

        .info {
          margin-right: 0;
        }

        .title {
          margin-bottom: 3px;

          font-size: 16px;

          font-weight: bold;
        }

        .notes {
          padding-top: 3px;
          font-size: 14px;

          md-icon {
            margin-left: 0;
            margin-right: 3px;
          }
        }

        .total-balancing {

          span {
            &:before {
              content: '(';
            }

            &:after {
              content: ')';
            }

            .balancing-positive {
              &:before {
                content: ' +';
              }
            }
          }
        }

        .secondary-text {

          &.time {
            margin-top: 3px
          }

          md-icon {
            margin-left: 0;
            margin-right: 3px;
          }
        }
      }

      .md-list-item-inner {
        padding: 6px 0;
      }

      .avatar {
        margin-right: 5px;

        font-size: 17px !important;

        &.occurrence-event {
          font-size: 18px !important;
        }
      }
    }
  }

  .md-subheader-inner {
    padding-bottom: 8px;
  }

  md-list-item {

    .md-list-item-inner {
      padding: 8px 0;

      z-index: 1;
    }

    .md-list-item-text {

      .title {
        @extend .text-semibold;
      }
    }
  }

  p {
    margin: 0;
  }

 	.md-subheader .md-subheader-inner {
    padding-top: 8px;
 	}

  .header-allocationMonitrips-view {
    min-height: 80px;
    max-height: 80px;

    background-color: #263238 !important;
    color: #fff !important;

    .md-toolbar-tools-top {

      .title {
        padding-left: 0;
      }
    }

    .info-allocationMonitrips {
      font-size: 14px;
    }

    md-icon {
      margin-left: 0;
      margin-right: 5px;

      color: #fff;
    }
  }

  .div-floating-list-button {
    bottom: 12px;
  }

  .div-allocationMonitrips-img {

    .allocationMonitrips-img {

      z-index: 2;

      .ng-image-gallery-modal {
        height: 225px;

        img {
          width: 100%;
        }
      }
    }

    .allocationMonitrips-map-img {
      position: absolute;
      right: 0;
    }
  }

  md-content {
    overflow-x: hidden;

    .door-occurrences {
      .div-total-occurrences {
        border-right: solid 1px rgba(0, 0, 0, .16);
      }
    }

    .div-occurrences,
    .door-occurrences,
    .centroid-occurrences {
      position: relative;

      width: 100%;

      > div {
        width: 100%;
      }

      .subtitle {
        padding: 12px;
      }

      .div-total-occurrences {
        padding-top: 12px;
        padding-bottom: 12px;

        cursor: pointer;

        md-icon {
          margin: 0;

          margin-right: 5px;
        }
      }
    }

    .list-item {
      padding-top: 12px;

      font-size: 14px;

      .info {
        margin-left: 0;
        margin-right: 0;
      }
    }

    .model-details {
      padding: 16px 16px;
      height: auto;
    }

    p {
      margin: 0;
    }
  }
}

@media screen and (max-width: $layout-breakpoint-xs) {
  .sidenav-allocationMonitrip-view,
  .allocationMonitrips-view {
    max-width: 100% !important;
    width: 100% !important;
  }
}

@media print {
  .allocationMonitrips-view {
    max-width: 100%;
    height: 100%;

    width: 100%;

    md-content {
      overflow: visible !important;
      /*
      md-list {
        .event-item {
          page-break-inside: avoid;
        }
      }
      */
    }

    md-toolbar {
      color: #000000;
      background-color: #fff;

      md-icon {
        margin-left: 0;
        margin-right: 2px;

        color: #000000;
      }
    }

    .content {
      display: block;


      .md-subheader {
        position: initial !important;

        margin: 12px 0;
      }

      table {
        max-width: 60%;

        tr,
        th,
        td {
          text-align: center !important;
        }

        tbody {

          td {
            padding: 0 12px 0 0 !important;
          }
        }
      }
    }
  }
}