.modelOperationLogs-view {
  max-width: 720px;
  min-height: 420px;
  width: 500px;

 	.md-subheader .md-subheader-inner {
    padding-top: 8px;
 	}

  .header-modelOperationLogs-view {
    min-height: 80px;
    max-height: 80px;

    background-color: #263238 !important;
    color: #fff !important;

    .md-toolbar-tools-top {

      .title {
        padding-left: 0;
      }
    }

    .info-modelOperationLogs {
      font-size: 14px;
    }

    md-icon {
      margin-left: 0;
      margin-right: 5px;

      color: #fff;
    }
  }

  .div-floating-list-button {
    bottom: 12px;
  }

  .div-modelOperationLogs-img {
    
    .modelOperationLogs-img {

      z-index: 2;

      .ng-image-gallery-modal {
        height: 225px;

        img {
          width: 100%;
        }
      }
    }

    .modelOperationLogs-map-img {
      position: absolute;
      right: 0;
    }
  }

  md-content {
    overflow-x: hidden;

    .door-occurrences {
      .div-total-occurrences {
        border-right: solid 1px rgba(0, 0, 0, .16);
      }
    }

    .div-occurrences,
    .door-occurrences,
    .centroid-occurrences {
      position: relative;

      width: 100%;

      > div {
        width: 100%;
      }

      .subtitle {
        padding: 12px;
      }

      .div-total-occurrences {
        padding-top: 12px;
        padding-bottom: 12px;

        cursor: pointer;

        md-icon {
          margin: 0;

          margin-right: 5px;
        }
      }
    }

    .list-item {
      padding-top: 12px;

      font-size: 14px;

      .info {
        margin-left: 0;
        margin-right: 0;
      }
    }

    .model-details {
      padding: 16px 16px;
      height: auto;
    }

    p {
      margin: 0;
    }
  }
}

@media print {

  .sidenav-modelOperationLogs-view {
    position: absolute;
    top: 0;
    left: 0;

    max-width: 100%;
    width: 100%;

    transform: translate(0, 0);

    overflow: visible;

    .modelOperationLogs-view {
      max-width: 100%;
      height: 100%;

      overflow: visible;

      .content-modelOperationLogs-view {
        .md-subheader {
          position: initial !important;

          margin: 12px 0;
        }

        table {
          max-width: 60%;

          tr,
          th,
          td {
            text-align: center !important;  
          }

          tbody {

            td {
              padding: 0 12px 0 0 !important;
            }
          }
        }
      }
    }
  }
}