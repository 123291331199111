$header-events-list-heigth: 65px;

#events {
  .header-events-list {
    height: $header-events-list-heigth;
    min-height: $header-events-list-heigth !important;
    max-height: $header-events-list-heigth;

    padding: 8px 12px;

    border-bottom: 1px solid rgba(0,0,0,.12);

    .div-total {

      > md-icon {
        margin-left: 0;
        margin-right: 5px;
      }
    }

    .div-list-controls {

      .order-by-directive {
        max-width: 180px;

        margin-right: 5px;
      }
    }
  }

  .events-list {
    height: 100%;

    padding-bottom: 36px;

    border-left: 1px solid rgba(0, 0, 0, 0.12);

    overflow-x: hidden;

    p {
      margin: 0;
    }

    [kb-item] {
      &.kb-active {
        background: #EEEEEE;
      }
    }

    .event-item {
      position: relative;
      height: auto !important;

      padding: 16px;

      border-bottom: 1px solid rgba(0,0,0,.08);
      text-transform: none;

      cursor: pointer;

      .avatar {
        font-size: 14px;

        md-icon {
          color: #FFFFFF;
        }
      }

      &.completed {
        background: #EEEEEE;

        .title,
        .notes {
          color: rgba(0, 0, 0, 0.54);
        }
      }

      &.selected {
        background: #FFF8E1;
      }

      .info {
        margin: 0 16px 0 8px;

        .title {
          font-size: 15px;
          font-weight: 500;
        }

        .secondary-title {
          margin-left: 5px;

          font-size: 12px !important;
        }

        md-icon {
          margin: 0;

          margin-right: 3px;
        }
      }

      .secondary-text {
        md-icon {
          margin: 0;

          margin-right: 3px;

          vertical-align: text-top;
        }
      }
    }
  }
}

@media screen and (max-width: $layout-breakpoint-xs) {
  #events {

    .header-events-list {

      .div-sub-total {
        margin-top: 0 !important;
      }
    }
  }
}