#terminals-installation {

  .installation-tabs {
    height: 100%;

    md-pagination-wrapper {
      width: 100% !important;

      transform: translate(0px, 0px);

      justify-content: space-between;
      align-content: space-between;

      md-tab-item {
        flex: 1;
      }
    }

    md-tabs-canvas,
    md-pagination-wrapper,
    md-tabs-canvas {
      height: 70px !important;
    }

    md-tabs-content-wrapper {
      margin-top: 21px;
    }
  }
}