#terminals-list-dashboard {
  .header-terminals-list {
    height: 45px;
    min-height: 45px !important;
    max-height: 45px;

    padding: 8px 24px;

    border-bottom: 1px solid rgba(0,0,0,.12);

    .div-total {

      > md-icon {
        margin-left: 0;
        margin-right: 5px;
      }
    }
  }

  .terminals-list {
    height: 100%;

    padding-bottom: 36px;

    border-left: 1px solid rgba(0, 0, 0, 0.12);

    overflow-x: hidden;

    p {
      margin: 0;
    }

    .div-terminals {
      width: 100%;
      height: auto;

      padding: 6px 12px;

      @include prefix(flex-flow, row wrap);
    }
  }
}

@media screen and (max-width: $layout-breakpoint-xs) {
  #terminals-list-dashboard {

    .terminals-list {
      .div-terminals {
        height: auto;

        padding: 6px 0;

        width: 100%;

        @include prefix(flex-flow, column nowrap);
      }
    }
  }
}