.events-view {
  max-width: 720px;
  min-height: 420px;
  width: 500px;

  .header-events-view {
    min-height: 50px;
    max-height: 100px;

    background-color: #263238 !important;
    color: #fff !important;

    .md-toolbar-tools-top {
      min-height: 50px;
      height: 50px;

      .title {
        padding-left: 0;
      }
    }

    .info-events {
      font-size: 14px;
    }

    md-icon {
      margin-left: 0;
      margin-right: 5px;

      color: #fff;
    }
  }

  .div-floating-list-button {
    bottom: 12px;
  }

  .div-events-img {

    .events-img {

      z-index: 2;

      .ng-image-gallery-modal {
        height: 225px;

        img {
          width: 100%;
        }
      }
    }

    .events-map-img {
      position: absolute;
      right: 0;
    }
  }

  md-content {
    overflow-x: hidden;

    md-tabs {
      width: 100%;

      .door-occurrences {
        .div-total-occurrences {
          border-right: solid 1px rgba(0, 0, 0, .16);
        }
      }

      .div-occurrences,
      .door-occurrences,
      .centroid-occurrences {
        position: relative;

        width: 100%;

        > div {
          width: 100%;
        }

        .subtitle {
          padding: 12px;
        }

        .div-total-occurrences {
          padding-top: 12px;
          padding-bottom: 12px;

          cursor: pointer;

          md-icon {
            margin: 0;

            margin-right: 5px;
          }
        }
      }

      .div-timeline {
        min-height: 300px;

        .div-people-graph {
          position: relative;
          min-height: 380px;

          .md-subheader-inner {
            padding: 8px 12px !important;
            padding-right: 0 !important;
          }
        }

        .timeline-events-list {
          min-height: 300px;

          .event-item {
            padding: 4px 16px 8px 16px;

            border: none !important;

            .info {
              margin-right: 0;
            }

            .events-info {
              width: 100%;;
            }

            .title {
              margin-bottom: 3px;

              font-size: 16px;

              font-weight: bold;
            }

            .notes {
              padding-top: 3px;
              font-size: 14px;

              md-icon {
                margin-left: 0;
                margin-right: 3px;
              }
            }

            .total-balancing {

              text-overflow: clip;
              overflow: visible;

              span {
                &:before {
                  content: '(';
                }

                &:after {
                  content: ')';
                }

                .balancing-positive {
                  &:before {
                    content: ' +';
                  }
                }
              }
            }

            .secondary-text {

              &.time {
                margin-top: 3px
              }

              md-icon {
                margin-left: 0;
                margin-right: 3px;
              }
            }
          }

          .md-list-item-inner {
            padding: 6px 0;
          }

          .avatar {
            margin-right: 10px;

            font-size: 17px !important;

            &.occurrence-event {
              font-size: 24px !important;
            }
          }
        }

        .md-subheader-content {
          font-weight: bold;
        }
      }

      .list-item {
        padding-top: 12px;

        font-size: 14px;

        .info {
          margin-left: 0;
          margin-right: 0;
        }
      }

      p {
        margin: 0;
      }
    }
  }

  .div-timeline {

    &--printing {

      people-graph {
        width: 700px !important;
      }
    }
  }
}

@media print {

  .sidenav-events-view {
    position: absolute;
    top: 0;
    left: 0;

    max-width: 100%;
    width: 100%;

    transform: translate(0, 0);

    overflow: visible;

    .events-view {
      max-width: 100%;
      height: 100%;

      overflow: visible;

      .header-events-view {

        .info {
          padding-right: 16px !important;
        }
      }

      .event-item {
        page-break-before: always !important;
        page-break-after: always !important;
        page-break-inside: avoid !important;

        * {
          page-break-inside: avoid !important;
        }
      }

      .content-events-view {
        .md-subheader {
          position: initial !important;

          margin: 12px 0;
        }

        table {
          max-width: 60%;

          tr,
          th,
          td {
            text-align: center !important;
          }

          tbody {

            td {
              padding: 0 12px 0 0 !important;
            }
          }
        }
      }

      md-tabs-wrapper,
      .lineY-div {
        display: none !important;
      }
    }
  }
}