.index {
  position: relative;

	&__img {
		position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;

    width: 293px;
    height: 169px;
	}
}
