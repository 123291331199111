md-dialog {

  &.terminals-camera {
    max-width: 720px;
    min-height: 420px;
    width: 500px;

    overflow: hidden;

    .md-subheader .md-subheader-inner {
      padding-top: 0;
    }

    .header-terminals-camera {
      background-color: #263238;
      color: #fff;

      .total-terminals-camera {
        max-height: 25px;
      }

      md-icon {
        margin: 0;
        color: #fff;
      }
    }

    .div-floating-list-button {
      bottom: 12px;
    }

    .avatar-tab {
      margin-bottom: 3px;
      margin-left: 5px;
      border-radius: 50%;
      color: white !important;
      font-size: 17px !important;
      width: 17px !important;
      height: 17px !important;
      min-width: 17px !important;
      min-height: 17px !important;
      line-height: 17px !important;
    }

    md-dialog-content {
      position: relative;
      max-height: 100%;
      min-height: 382px;

      padding: 0 !important;

      display: block;
      overflow-x: hidden;
      overflow-y: hidden;

      md-tabs.md-default-theme md-ink-bar, md-tabs md-ink-bar{
        color: rgb(63,81,181) !important;
        background-color:rgb(63,81,181) !important;
      }

      .info-header {
        color: white;
      }

      .div-event-img {
        min-height: 235px;

        .event-img {
          z-index: 2;

          .ng-image-gallery-modal {
            height: 225px;
          }
        }

        .div-legend-img {
          position: absolute;
          bottom: 0;
          left: 0;

          width: 100%;

          padding: 12px;

          z-index: 5;

          color: white;
          font-size: 14px;
          font-weight: bold;

          background-color: rgba(0, 0, 0, .26);

          * {
            color: white;
          }

          .notes {
            margin-right: 90px;
          }
        }

        .event-map-img {
          position: absolute;
          right: 0;
        }
      }

      .div-gps-img {
        position: absolute;
        opacity: 0.7;
        right: 0;
        padding: 5px;
        z-index: 10;
      }

      .div-img-loading {
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.38);
        z-index: 2;

        .div-icon-loading {
          margin: auto;
          display: inline-table;
        }
      }


      .terminal-configuration-fields {
        padding: 16px;
        margin-top: 15px;

        .md-chips {
          box-shadow: none;
          font-size: 12px;
        }

        .terminalModel-info {
          font-size: 14px;

          md-icon {
            margin-right: 5px;
          }
        }
      }

      .list-item {
        padding-top: 10px;

        font-size: 14px;

        //color: white;

        .title {
          font-size: 18px;
          font-weight: bold;

          .event-edit-button {
            margin-left: 0;
            padding-left: 0;

            font-size: 22px;
          }
        }

        .info {
          margin-left: 0;
          margin-right: 0;

          .notes {
            md-icon {
              margin-left: 0;
              margin-right: 5px;
              color: white;
            }
          }
        }

        .div-event-buttons {
          button {
            margin-top: 4px;
            margin-bottom: 0;
          }
        }

        md-icon {
          margin-right: 5px;
          color: white;
        }
      }

      .div-map-img {
        padding: 6px;

        overflow: hidden;

        .div-gmaps {
          min-height: 350px;

          .gmaps {
            overflow: hidden;
          }
        }

        .div-position-not-found {
          position: absolute;
          bottom: 0;
          left: 0;

          height: 100%;
          width: 100%;

          opacity: .7;

          background-color: #FFF;

          @include prefix(transition, all .3s ease-out);

          &.loading {
            margin-bottom: -100px;
          }

          > div {
            text-align: center;
            padding: 12px;
          }
        }
      }


      .trackerReadings-list {
        height: 100%;

        padding-bottom: 36px;

        border-left: 1px solid rgba(0, 0, 0, 0.12);

        overflow-x: hidden;
        overflow-y: auto;

        p {
          margin: 0;
        }

        .div-group {
          height: auto;

          padding: 0 0 6px 0;

          &:not(:last-of-type) {
            border-bottom: solid 1px rgba(0, 0, 0, .08);
          }

          .md-subheader {

            .div-totals {
              min-width: 370px;

              .info {
                .disabled {
                  opacity: .4;
                }
              }

              > div {
                margin: 0 8px;

                &:first-of-type {
                  margin-left: 0;
                }
              }
            }

            .button-show-hide-trackerReadings {
              font-size: 13px;
            }

            .md-subheader-inner {
              padding: 8px 16px 6px;
            }
          }

          .div-trackerReadings-group {
            width: 100%;

            @include prefix(flex-flow, row wrap);
          }
        }
      }

      .trackerReading-item {
        position: relative;

        height: auto !important;
        min-height: 20%;

        min-width: $div-trackerReading-width !important;
        max-width: $div-trackerReading-width;
        width: $div-trackerReading-width;

        margin: 6px;

        padding: 8px 0 0;

        text-transform: none;

        cursor: pointer;

        .div-event-img {
          min-height: 215px;
          max-width: 330px;

          width: 100%;

          .event-img {
            z-index: 1;

            // .ng-image-gallery-modal {
            //   // height: 205px;
            // }
          }

          &:hover {
            .event-img-png {
              display: block;

              z-index: 2;
            }
          }

          .event-img-png {
            position: absolute;
            top: 0;
            left: 0;

            width: 100%;
            height: 97%;

            display: none;

            img {
              box-shadow: none;
            }
          }
        }

        .div-trackerReading-item {
          color: #FFFFFF;

          .avatar {
            font-size: 14px;
          }

          .info {
            min-width: 190px;
            max-width: 193px;

            margin-right: 4px;

            .secondary-title {
              margin-left: 5px;

              font-size: 12px !important;
            }

            .notes {

              md-icon {
                margin-right: 3px;

                color: #FFFFFF;
              }
            }
          }

          .secondary-info {
            md-icon {
              margin-right: 3px;

              color: #FFFFFF;
            }
          }

          .secondary-text {

            md-icon {
              margin: 0;
              margin-right: 5px;
            }
          }

          .trackerReading-address {
            max-height: 35px;

            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }


      md-input-container {
        margin: 0;
      }

      p {
        margin: 0;
      }
    }

    md-dialog-actions {
      position: relative;
      overflow-y: hidden;

      overflow-x: auto;

      border-top: 1px solid rgba(0, 0, 0, 0.12);

      background-color: rgba(0, 0, 0, 0.03);
    }
  }
}

.selectTerminalModel .select-terminalModel-header-searchbox {
  border: none;
  outline: none;
  height: 100%;
  width: 100%;
  padding: 0;
}

.selectTerminalModel .select-terminalModel-select-header {
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0.14), 0 0 0 0 rgba(0, 0, 0, 0.12);
  padding-left: 10.667px;
  height: 48px;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  width: auto;
  padding: 0;

  md-input-container {
    width: 100%;
  }

}


@media screen and (min-width: $layout-breakpoint-xs) {
  md-dialog {
    &.terminals-camera {
      min-height: 95%;
      max-height: 100%;

      form {
        flex: 1 1 100%;
      }

      md-dialog-content {
        flex: 1 1 100%;
        display: flex;

        md-tabs {
          //min-height: 582px !important;
          flex: 1 1 100%;
        }
      }
    }

    &.extended-width-height {
      min-width: 95% !important;
      max-width: 100% !important;
      min-height: 95% !important;
      max-height: 100% !important;

      form {
        flex: 1 1 100% !important;
      }

      md-dialog-content {
        flex: 1 1 100% !important;
        display: flex;

        md-tabs {
          //min-height: 582px !important;
          flex: 1 1 100% !important;
        }
      }
    }
  }
}

@media screen and (max-width: $layout-breakpoint-sm) {
  md-dialog {
    &.terminals-camera {
      md-dialog-content {
        md-tabs {
          height: 100%;
        }
      }
    }
  }
}