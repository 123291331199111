.admin-action-list {
  &__center-align {
    text-align: center !important;
  }

  &__input {
    margin-bottom: 0;
    margin-top: 30px;
  }

  .limit-select,
  .page-select {
    width: auto !important;
  }

  &__row {
    &:hover {
      background: #E8EAF6;
      cursor: pointer;
    }

    &:hover &__delete {
      opacity: 1;
    }

    &__delete {
      opacity: 0;
      transition: all 0.5s;
    }
  }
}
