.anomaly-report {
  &__row {
    &:hover {
      background: #E8EAF6;
      cursor: pointer;
    }
  }

  &__filters-buttons {
    text-align: right;
  }

  .filters-frame {
    background-color: #FFFFFF;

    .md-input-date {
      margin-left: 10px;
      margin-right: 10px;
      width: 120px;
    }
    .filters {
      height: 50px;
    }
  }
  .page-select {
    &.ng-scope {
      width: auto;
    }
  }

  .center-align {
    text-align: center !important;
  }

  .limit-select {
    &.ng-scope {
      width: auto;
    }
  }

  .table.md-table td.md-cell, table.md-table th.md-column {
    vertical-align: middle;
    text-align: center;
  }
  &__assessment {
    display: flex;
    padding: 20px;
    padding-top: 10px;
    flex-wrap: wrap;

    &__text {
      width: 60%;

      &__row {
        display: flex;
        line-height: 50px;

        &__title {
          width: 40%;
          font-weight: bold;
        }
      }
    }
    &__images {
      width: 40%;

      &__title {
        font-weight: bold;
        line-height: 30px;

        &__info {
          font-weight: normal;
        }
      }

      &__wrapper {
        padding-bottom: 10px;
        &__img {
          border: solid 1px #455A64;
          max-width: 100%;
          max-height: 240px;
          position: relative;
          z-index: 2;
        }
      }
    }
  }
}

.custom-dialog {
  max-height: 100%;
  width: 55%;
}
