$background-color: #394b6d;
$search-height: 45px;

#centroids-report {

  background-color: rgb(245, 245, 245);

  .detail-resize {
    width: 72%;
  }

  .main-sidenav {

    &.open {
      z-index: 60;
    }
  }

  md-sidenav {

    background-color: rgb(250, 250, 250);

    width: 500px;

    max-width: 500px;

    .header {
      color: #FFFFFF;
      background-color: $background-color;

      .logo {
        padding-top: 27px;


        .logo-icon {
          margin: 0 16px 0 0;

          md-icon {
            color: #FFFFFF;
          }
        }

        .logo-text {
          font-size: 24px;
        }
      }
    }

    md-content {

      .item {
        cursor: pointer;

        &.selected {
          background-color: rgba(0, 0, 0, 0.06);
        }
      }

      .md-subheader.md-primary {
        color: rgba(0, 0, 0, 0.56);
      }
    }

    .div-add-button {
      padding: 0 32px 6px;
    }
  }

  .center {

    .header {
      height: 80px;
      min-height: 80px;
      max-height: 136px;

      padding: 12px;
      padding-left: 0;

      background-color: $background-color;
      color: #FFFFFF;

      .search-wrapper {
        background: #fff;

        .div-search {
          height: $search-height;
          line-height: $search-height;
          padding: 10px;

          .input-search {
              height: $search-height;

              padding-left: 16px;
              margin: 0;

              border-radius: 0;
              border: none;

              color: rgba(0,0,0,.54);

              appearance: none;
              -moz-appearance: none;
              -webkit-appearance: none;
          }
        }
      }

      .print-wrapper {
        padding-top: 50px;
        margin-left: 8px;

        md-fab-trigger {

          button {
            min-width: 50px;
            max-width: 50px;

            margin: 0;

            @include prefix(transition, opacity.3s ease-in-out);

            &:disabled {
              opacity: .4;
            }
          }
        }
      }
    }

    .centroids-report-tabs {

      .div-tab-label {

        .div-icon-loading {
          margin-top: 4px;
          margin-left: 8px;
        }
      }

      md-tab-content {
        overflow-y: hidden;
      }
    }
  }
}

@media screen and (max-width: $layout-breakpoint-sm) {
  #events {

    .header {

      .div-search {
        border-left: 1px solid rgba(0, 0, 0, 0.12);
      }
    }

    .center {

      .header {
        padding-left: 16px;
      }
    }
  }
}

@media print {
  #centroids-report {
    
    .center {
      .centroids-report-tabs {
    
        .div-tab-label {
    
          md-tab-content {
            overflow-y: auto;
          }
        }
      }
    }
  }
}