.violation {
  display: flex;
  padding-top: 5px;
  width: 100%;
  flex-wrap: wrap;
  position: relative;

  &__loading {
    background: rgba(#F5F5F5, 0.9);
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1000;

    &__spinner {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
    }
  }

  &__text {
    width: 60%;

    &__row {
      display: flex;
      line-height: 30px;

      &__option {
        display: flex;
        justify-content: space-between;
      }

      &__title {
        width: 35%;
        font-weight: bold;
      }

      &__options {
        width: 60%;
      }

      &__actions {
        margin-top: 40px;
      }
    }
  }

  &__images {
    width: 40%;

    &__title {
      font-weight: bold;
      line-height: 40px;

      &__info {
        font-weight: normal;
      }
    }

    &__wrapper {
      width: 320px;
      margin-bottom: 4px;

      &__img {
        border: solid 1px #455A64;
        max-width: 100%;
        position: relative;
        z-index: 2;
      }
    }
  }

  &__footer {
    width: 100%;
    margin-top: 20px;

    &__buttons {
      padding-top: 10px;
      display: flex;
      align-items: center;

      &__left {
        text-align: left;
      }

      &__center {
        text-align: center;
      }

      &__right {
        text-align: right;
      }
    }
  }
}
