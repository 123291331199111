$mobileWidthLayout: 800px;

$ebb: #e8e1e1;
$alto: #e0e0e0;
$astronaut: #26496E;
$wild-willow: #99CC66;
$sea-buckthorn: #F8B133;
$bittersweet: #FF6666;
$san-marino: #3F51B5;
$sapphire: #303F9F;
$dodger-blue: #536DFE;
