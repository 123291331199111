.header-centroids-list {
  height: 64px;
  min-height: 64px !important;
  max-height: 64px;

  padding: 8px 24px;

  border-bottom: 1px solid rgba(0,0,0,.12);

  .div-total {

    > md-icon {
      margin-left: 0;
      margin-right: 5px;
    }
  }

  .div-sub-total {
    flex-flow: row wrap;
    margin-right: 10px;
  }
}

.centroids-list {
  height: 100%;

  padding-bottom: 36px;

  border-left: 1px solid rgba(0, 0, 0, 0.12);

  overflow-x: hidden;

  p {
    margin: 0;
  }

  .md-subheader .md-subheader-inner {
    padding-top: 0;
  }
}

.centroid-item {
  position: relative;
  height: auto !important;

  padding: 16px;
  
  border-bottom: 1px solid rgba(0,0,0,.08);
  text-transform: none;
  
  cursor: pointer;

  .avatar {
    font-size: 14px;
  }

  .info {
    margin: 0 16px 0 8px;

    .title {
      font-size: 15px;
      font-weight: 500;
    }

    md-icon {
      margin: 0;

      margin-right: 3px;
    }

    .secondary-title {
      margin-left: 5px;

      font-size: 12px !important;
    }
  }

  .secondary-text {
    md-icon {
      margin: 0;

      margin-right: 3px;
    }
  }
}

@media screen and (max-width: 450px) {
  .header-centroids-list {
    height: 120px;
    min-height: 120px !important;
    max-height: 120px;

    padding: 8px 24px;

    flex-direction: column !important;

    border-bottom: 1px solid rgba(0,0,0,.12);

    .div-total {

      > md-icon {
        margin-left: 0;
        margin-right: 5px;
      }
    }
  }
}