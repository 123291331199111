$div-trackerReading-width: 345px;

md-dialog {

  &#allocationTrackerReading-list {
    max-width: 100% !important;
    min-height: 95% !important;
    width: 95% !important;

    .transformable {
        -webkit-transition: min-height 150ms linear;
        -moz-transition: min-height 150ms linear;
        -o-transition: min-height 150ms linear;
        -ms-transition: min-height 150ms linear;
        transition: min-height 150ms linear;
      }

    .expanded {
      min-height: 250px;
    }

    .collapsed {
      min-height: 64px;
    }

    .header-trackerReading-list {
      background-color: #394b6d;
      color: #FFFFFF;


      md-icon {
        margin: 0;
        color: #FFFFFF;
      }

      &.md-subheader {
        min-height: 70px;
      }

      .md-button[disabled] {

        md-icon {
          opacity: .4;
        }
      }

      .total-trackerReadings-list {
        max-height: 25px;
      }

      .secondary-info {

        md-icon {
          margin-right: 5px;
        }
      }

      .filters {
        padding: 5px 0;
        font-size: 14px;

        .date-range-filter {
          margin-top: 10px;

          md-input-container {
            md-datepicker {
              input {
                color: #FFFFFF;
              }
            }  
          }    
        }

        .terminal-filter {
          margin-top: 10px;
        }
  
        .item {
          cursor: pointer;
  
          &.selected {
            background-color: rgba(0, 0, 0, 0.06);
          }
  
          label {
            cursor: pointer;
          }
        }
  
        .select-filter {
          padding: 0 24px 0 16px;
        }
  
        md-icon {
          color: rgba(0, 0, 0, .56);
        }
  
        md-input-container {
          max-height: 30px;
        }
  
        md-select {
          height: 35px !important;
        }
  
        mdp-time-picker {
  
          > div {
            padding: 0 24px 0 12px;
  
            md-input-container {
              @include prefix(flex, 1);
            }
          }
        }
      }      
    }

    &.md-locked-open {
  
      .header {
        color: #FFFFFF;

        .logo {
          padding-top: 27px;

          .logo-icon {

            md-icon {
              color: #FFFFFF;
            }
          }
        }
      }
    }

    &:not(.md-locked-open) {

      .header {
        height: 150px;
        min-height: 150px;
        max-height: 150px;
        padding-top: 24px;
      }
    }

    .md-subheader {
      z-index: 3 !important;

      box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.14), 0px 2px 2px 0px rgba(0, 0, 0, 0.08), 0px 3px 1px -2px rgba(0, 0, 0, 0.08);

      .div-loading-trackerReadings-list {
        @include prefix(transition, all .3s ease-in-out);
      }
    }

    md-input-container {
      margin-bottom: 0;

      > * {
        color: #FFFFFF !important;
      }

      input {
        border-bottom-color: #FFFFFF !important;
      }
    }

    .div-floating-list-button {
      bottom: 12px;
    }

    .trackerReadings-list {
      height: 100%;

      padding-bottom: 36px;

      border-left: 1px solid rgba(0, 0, 0, 0.12);

      overflow-x: hidden;

      p {
        margin: 0;
      }

      .div-group {
        height: auto;

        padding: 0 0 6px 0;

        &:not(:last-of-type) {
          border-bottom: solid 1px rgba(0, 0, 0, .08);
        }

        .md-subheader {

          .div-totals {
            min-width: 370px;

            .info {
              .disabled {
                opacity: .4;
              }
            }

            > div {
              margin: 0 8px;

              &:first-of-type {
                margin-left: 0;
              }
            }
          }

          .button-show-hide-trackerReadings {
            font-size: 13px;
          }

          .md-subheader-inner {
            padding: 8px 16px 6px;
          }
        }

        .div-trackerReadings-group {
          width: 100%;

          @include prefix(flex-flow, row wrap);
        }
      }
    }

    .trackerReading-item {
      position: relative;

      height: auto !important;
      min-height: 20%;

      min-width: $div-trackerReading-width !important;
      max-width: $div-trackerReading-width;
      width: $div-trackerReading-width;

      margin: 6px;

      padding: 8px 0 0;

      text-transform: none;

      cursor: pointer;

      .div-event-img {
        min-height: 215px;
        max-width: 330px;

        width: 100%;

        .event-img {
          z-index: 1;

          .ng-image-gallery-modal {
            // height: 205px;
          }
        }

        &:hover {
          .event-img-png {
            display: block;

            z-index: 2;
          }
        }

        .event-img-png {
          position: absolute;
          top: 0;
          left: 0;

          width: 100%;
          height: 97%;

          display: none;

          img {
            box-shadow: none;
          }
        }
      }

      .div-trackerReading-item {
        color: #FFFFFF;

        .avatar {
          font-size: 14px;
        }

        .info {
          min-width: 190px;
          max-width: 193px;

          margin-right: 4px;

          .secondary-title {
            margin-left: 5px;

            font-size: 12px !important;
          }

          .notes {

            md-icon {
              margin-right: 3px;

              color: #FFFFFF;
            }
          }
        }

        .secondary-text {

          md-icon {
            margin: 0;
            margin-right: 5px;
          }
        }

        .trackerReading-address {
          max-height: 35px;

          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }

    md-content {
      position: relative;

      padding: 0 !important;

      display: block;
      overflow-x: hidden;

      .div-event-img {

        .div-wrapper-event-img {
          z-index: 1;

          background-color: #FFFFFF;

          .div-img {
            position: relative;

            .event-img {
              width: 100%;

              z-index: 2;

              .ng-image-gallery-modal {
                height: 225px;

                .galleria-image {
                  margin: 0;
                }
              }
            }

            &:hover {
              .event-img-png {
                display: block;

                z-index: 2;
              }
            }

            .event-img-png {
              position: absolute;
              top: 0;
              left: 0;

              width: 100%;
              height: 97%;

              display: none;

              img {
                box-shadow: none;
              }
            }
          }
        }

        .event-map-img {
          position: absolute;
          right: 0;
        }
      }

      .list-item {
        width: 100%;

        padding: 12px 8px;

        font-size: 14px;

        color: white;

        .title {
          font-size: 18px;
          font-weight: bold;

          .event-edit-button {
            margin-left: 0;
            padding-left: 0;

            font-size: 22px;
          }
        }

        .info {
          margin-left: 0;
          margin-right: 0;
        }

        .div-event-buttons {
          button {
            margin-top: 4px;
            margin-bottom: 0;
          }
        }

        md-icon {
          color: white;
        }
      }

      md-input-container {
        margin: 0;
      }

      p {
        margin: 0;
      }
    }

    md-dialog-actions {
      position: relative;
      overflow-y: hidden;

      overflow-x: auto;

      border-top: 1px solid rgba(0, 0, 0, 0.12);

      background-color: rgba(0, 0, 0, 0.03);
    }
  }
}

@media screen and (max-width: $layout-breakpoint-sm) {
  md-dialog {

    &#allocationTrackerReading-list {
      min-height: 100% !important;
      width: 100% !important;
    }
  }
}

@media print {

  // html, body, .main, .tabs, .tabbed-content { float: none; }

  body {
    -webkit-print-color-adjust: exact !important;
  }

  .page-break-after {
    position: relative !important;
    display: block !important;
    page-break-before: always !important;
    page-break-after: always !important;
    float: none;
  }

  .md-dialog-container {
    height: auto !important;
    
    overflow: visible;
    
    z-index: 999;

    border: none !important;
    box-shadow: none !important;
  }

  md-dialog {

    &#allocationTrackerReading-list {
      position: relative;

      min-height: 100%;
      min-width: 100%;

      height: 100%;
      width: 100%;

      display: block !important;

      overflow: visible !important;

      md-content {
        position: relative !important;
        display: block !important;
        overflow: visible !important;

        border: none !important;
        box-shadow: none;

        .div-group {
          float: none !important;

          border-bottom: none;

          .md-subheader {
            position: fixed !important;
            top: auto !important;

            margin: 12px 0;

            box-shadow: 0px -1px 5px 0px rgba(0, 0, 0, 0.14)
              , 0px 2px 2px 0px rgba(0, 0, 0, 0.08)
              , 0px 3px 1px -2px rgba(0, 0, 0, 0.08)
              , 0px 3px 1px -2px rgba(0, 0, 0, 0.08);
          }
        }

        .div-trackerReadings-group {
          display: block;

          > * {
            float: left !important;
          }

          .trackerReading-item {
            height: 360px !important;
            max-height: 360px !important;

            &:first-of-type,
            &:nth-of-type(2) {
              margin-top: 100px !important;
            }

            .div-event-img {
              min-height: 205px;
              max-height: 205px;

              margin-bottom: 5px;
            }
          }
        }

        .div-trackerReadings-group,
        .trackerReading-item,
        .div-event-img,
        .div-trackerReading-item {
          position: relative !important;
          page-break-inside: avoid !important;
        }
      }

      md-toolbar {
        color: #000000;
        background-color: #FFFFFF;

        md-icon {
          margin-left: 0;
          margin-right: 2px;

          color: #000000;
        }
      }

      .content {
        display: block;

        .md-subheader {
          position: initial !important;

          margin: 12px 0;
        }
      }
    }
  }
}