#terminals-list-dashboard {
  .header-terminals-list {
    height: 45px;
    min-height: 45px !important;
    max-height: 45px;

    padding: 8px 24px;

    border-bottom: 1px solid rgba(0,0,0,.12);

    .div-total {

      > md-icon {
        margin-left: 0;
        margin-right: 5px;
      }
    }
  }
}

@media screen and (max-width: $layout-breakpoint-xs) {
  #terminals-list-dashboard {

    .terminals-status-list {
      .div-terminals {
        height: auto;

        padding: 6px 0;

        .div-terminals {
          width: 100%;

          @include prefix(flex-flow, column nowrap);
        }
      }
    }
  }
}