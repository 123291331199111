md-dialog {

  &.user-password {
    overflow: hidden;

    md-toolbar {

      .title {
        font-size: 17px;
      }
    }

    md-dialog-content {
      position: relative;
      min-height: 200px;

      padding: 16px;

      display: block;
      overflow-x: hidden;

      .div-user-centroids {

        .header-user-centroids {
          height: 46px;
          min-height: 46px;
          width: 108%;

          margin-bottom: 12px;
          margin-left: -20px;
          padding: 12px 16px;

          > span {
            padding: 12px 0 0;
          }
        }

        .div-user {

          .user-item {
            cursor: move !important;
          }
        }
      }

      .div-edit-user {
        padding: 0 8px;
      }

      md-input-container {

        md-icon {
          color: rgba(0,0,0,0.54);
        }

        > label {
          padding-left: 0 !important;
        }
      }

      mdp-time-picker {

        > div {

          > button {
            width: 32px !important;

            padding-left: 0 !important;
            padding-right: 0 !important;

            md-icon {
              margin-left: 0 !important;
              margin-right: 0 !important;
            }
          }

          md-input-container {
            margin-left: 0 !important;

            @include prefix(flex, 1);
          }
        }
      }
    }

    md-dialog-actions {
      position: relative;
      overflow-y: hidden;

      overflow-x: auto;

      border-top: 1px solid rgba(0, 0, 0, 0.12);

      background-color: rgba(0, 0, 0, 0.03);
    }
  }
}

@media screen and (max-width: $layout-breakpoint-xs) {

  md-dialog {

    &.user-password {
      width: 100%;
      height: 100%;

      .div-autocomplete,
      .input-date {
        max-height: 35px;
      }

      .div-autocomplete {
        padding-left: 3px;
        margin: 18px 0;

        &:first-child,
        md-input-container {
          margin-top: 0;
        }

        md-input-container + button {
          top: 5px;
        }
      }

      md-input-container {
        max-height: 70px;
      }
    }
  }
}

@media screen and (min-width: $layout-breakpoint-xs) {

  md-dialog {

    &.user-password {
      min-width: 400px;
      
      .div-autocomplete {

        &:not(:first-child) {
          margin-left: 10px;
        }
      }

      md-input-container {

        &:not(:first-child) {
          margin-left: 10px;
        }
      }

      md-dialog-content {

        .div-user-inputs {
          max-height: 70px;
        }
      }
    }
  }
}

@media screen and (max-width: $layout-breakpoint-md) {

  md-dialog {

    &.user-password {
      width: 80%;
    }
  }
}

@media screen and (min-width: $layout-breakpoint-sm) {

  md-dialog {

    &.user-password {
      max-width: 500px;
      width: 90%;
    }
  }
}

@media screen and (max-width: $layout-breakpoint-sm) {

  md-dialog {

    &.user-password {
      width: 90%;
    }
  }
}