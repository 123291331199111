.div-generic-search-autocomplete {

  .div-autocomplete-generic-search-autocomplete {

    > md-icon {
      margin-top: 25px;
      margin-bottom: 0;
    }

    md-input-container {
      margin: 18px 0;
    }
  }
}