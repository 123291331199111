$background-color: #394b6d;

#employees {

  background-color: rgb(245, 245, 245);

  .main-sidenav {

    &.open {
      z-index: 60;
    }
  }

  md-sidenav {

    background-color: rgb(250, 250, 250);

    .header {
      color: #FFFFFF;
      background-color: $background-color;

      .logo {
        padding-top: 27px;


        .logo-icon {
          margin: 0 16px 0 0;

          md-icon {
            color: #FFFFFF;
          }
        }

        .logo-text {
          font-size: 24px;
        }
      }
    }

    md-content {

      .item {
        cursor: pointer;

        &.selected {
          background-color: rgba(0, 0, 0, 0.06);
        }
      }
    }

    .content {
      padding: 12px 0;
    }

    .div-add-button {
      padding: 0 32px 6px;
    }
  }

  .center {

    .header {
      height: 110px;
      min-height: 100px;
      max-height: 136px;

      padding: 12px;
      padding-left: 0;

      background-color: $background-color;
      color: #FFFFFF;

      .search-wrapper {
        background: #fff;
        
        .div-search {
          height: 56px;
          employee-height: 56px;
          padding: 18px;

          .input-search {
              height: 56px;
              
              padding-left: 16px;
              margin: 0;

              border-radius: 0;
              border: none;
              
              color: rgba(0,0,0,.54);

              appearance: none;
              -moz-appearance: none;
              -webkit-appearance: none;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $layout-breakpoint-sm) {
  #employees {

    .header {

      .div-search {
        border-left: 1px solid rgba(0, 0, 0, 0.12);
      }
    }

    .center {

      .header {
        padding-left: 16px;
      }
    }
  }
}