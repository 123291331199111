$background-color: #394b6d;
$search-width: 320px;
$search-height: 50px;

searchbar {
  .div-toggle-searchbar {
    position: absolute;
    width: 30px;
    height: 60px;
    top: 20px;
    left: $search-width;

    // padding-right: 10px;
    padding: 3px 3px 0 0;

    border: solid 1px rgba(0, 0, 0, 0.2);
    border-left-width: 0;

    background: $background-color;
    color: white;

    font-size: 1em;
    line-height: 60px;
    text-align: center;

    z-index: 0;

    cursor: pointer;

    @include prefix(transition, all .3s);

    box-sizing: border-box;
    box-shadow: 5px 2px 6px rgba(0, 0, 0, .12) inset;

    &.closed {
      left: 0;
    }
  }
}

.searchbar {
  position: absolute;
  left: 0;
  top: 0;

  min-width: 200px;
  width: $search-width;
  height: 100%;

  @include prefix(transition, all .3s);

  z-index: 9;

  &.closed {
    left: -$search-width;
  }

  .div-organizations {
    height: 100%;

    box-shadow: 0 2px 6px rgba(0, 0, 0, .54);
    background-color: rgb(245, 245, 245);

    @include prefix(transition, all .2s);

    .center {

      .header {
        height: 100px;
        min-height: 100px;
        max-height: 100px;

        padding: 12px;

        background-color: $background-color;
        color: #FFFFFF;
        font-size: 14px;

        .search-wrapper {
          background: #fff;
          
          .div-search {
            height: $search-height;
            min-height: $search-height;

            padding: 14px;

            .input-search {
                height: $search-height;
                
                padding-left: 16px;
                margin: 0;

                border-radius: 0;
                border: none;
                
                color: rgba(0,0,0,.54);

                appearance: none;
                -moz-appearance: none;
                -webkit-appearance: none;
            }
          }
        }
      }

      .header-organizations-list {
        height: 60px;
        min-height: 60px !important;
        max-height: 60px;

        padding: 8px 10px;

        border-bottom: 1px solid rgba(0,0,0,.12);

        font-size: 14px;

        .div-total {

          > md-icon {
            margin-left: 0;
            margin-right: 5px;
          }
        }
      }

      .div-list-orderBy {
        min-width: 165px;
        margin-top: 10px
      }

      .organizations-list {
        height: 100%;

        padding-bottom: 50px;

        border-left: 1px solid rgba(0, 0, 0, 0.12);

        overflow-x: hidden;

        p {
          margin: 0;
        }

        .md-subheader .md-subheader-inner {
          padding-top: 0;
        }
      }

      .organization-item {
        position: relative;
        height: auto !important;

        padding: 16px;
        
        border-bottom: 1px solid rgba(0,0,0,.08);
        text-transform: none;
        
        cursor: pointer;

        .avatar {
          font-size: 14px;
        }

        .info {
          margin: 0 16px 0 8px;

          .title {
            font-size: 15px;
            font-weight: 500;
          }

          .secondary-title {
            margin-left: 5px;

            font-size: 12px !important;
          }

          md-icon {
            margin-top: 0;
            margin-bottom: 0;
            margin-left: 0;
          }
        }

        .secondary-text {
          md-icon {
            margin: 0;
          }
        }

        .selected {
          background-color: rgba(0, 0, 0, .12);
        }
      }

      .organization-people-count {
        @include prefix(transition, all .2s);
      }
    }
  }
}

@media screen and (max-width: $layout-breakpoint-xs) {
  .searchbar {
    .div-organizations {

      .header {

        .div-search {
          border-left: 1px solid rgba(0, 0, 0, 0.12);
        }
      }

      .center {

        .header {
          padding-left: 16px;
        }
      }
    }

    md-autocomplete {
      max-width: 80%;
      width: 80%;
    }
  }
}