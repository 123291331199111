#layout-content-with-toolbar {

  #toolbar {
    // padding: 0 0 0 16px;
    
    .logo {
      min-width: 140px;
      max-width: 140px;

      margin-left: 16px;

      .logo-image {
        display: block;
        background: material-color('light-blue', '600');
        width: 32px;
        min-width: 32px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        color: #FFFFFF;
        font-size: 16px;
        font-weight: 500;
        border-radius: 2px;
      }

      .logo-text {
        color: rgba(0, 0, 0, 0.87);
        margin-left: 16px;
        font-size: 16px;
      }
    }

    .div-controls-toolbar {
      max-width: 305px;

      .div-branchOrganization {
        max-width: 200px;

        button {
          height: $toolbarHeight;

          margin-left: 0;
        }
      }

      .div-user {
        min-width: 103px;
      }
    }
  }
}

md-menu-content {
  md-menu-item {
    padding: 4px 0;
  }
}

@media screen and (max-width: $layout-breakpoint-xs) {
  #layout-content-with-toolbar {

    #toolbar {
      .div-controls-toolbar {
        max-width: 155px;

        .div-branchOrganization {
          max-width: 70px;
        }
      }
    }
  }
}