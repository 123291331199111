md-dialog {

  &.allocation-edit {
    overflow: hidden;

    md-toolbar {

      .title {
        font-size: 17px;
      }
    }

    md-dialog-content {
      display: block;
      position: relative;

      padding: 16px;

      md-input-container {

        md-icon {
          color: rgba(0,0,0,0.54);
        }

        > label {
          padding-left: 0 !important;
        }
      }

      .div-autocomplete {

        &:first-child {
          padding-left: 3px;
        }

        > md-icon {
          max-width: 24px;

          padding-top: 3px;
          margin: 22px 6px 0 0;
        }
      }
    }

    md-dialog-actions {
      position: relative;
      overflow-y: hidden;

      overflow-x: auto;

      border-top: 1px solid rgba(0, 0, 0, 0.12);

      background-color: rgba(0, 0, 0, 0.03);
    }
  }
}

@media screen and (max-width: $layout-breakpoint-xs) {

  md-dialog {

    &.allocation-edit {
      width: 100%;

      .div-input-lines {

        > :not(:first-child) {
          margin-top: 0;
        }
      }

      .div-autocomplete,
      .input-date {
        max-height: 35px;
      }

      .div-autocomplete {
        padding-left: 3px;
        margin: 18px 0;

        &:first-child {
          margin-top: 0;
        }
      }

      md-input-container {
        max-height: 70px;
      }
    }

  }
}

@media screen and (min-width: $layout-breakpoint-xs) {

  md-dialog {

    &.allocation-edit {
      min-width: 600px;

      .div-autocomplete {

        &:not(:first-child) {
          margin-left: 10px;
        }
      }

      md-input-container {

        &:not(:first-child) {
          margin-left: 10px;
        }
      }

      md-dialog-content {

        .div-line-inputs {
          max-height: 70px;
        }
      }
    }

  }
}

@media screen and (max-width: $layout-breakpoint-md) {

  md-dialog {

    &.allocation-edit {
      width: 80%;
    }
  }
}

@media screen and (min-width: $layout-breakpoint-sm) {

  md-dialog {

    &.allocation-edit {
      max-width: 780px;
      width: 90%;
    }
  }
}

@media screen and (max-width: $layout-breakpoint-sm) {

  md-dialog {

    &.allocation-edit {
      width: 90%;
    }
  }
}