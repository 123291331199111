.toast-loading-component {
  .div-toast-loading {
    md-progress-circular svg path {
      stroke: #FFFFFF;
    }
  }

  button {
    font-weight: bold;
  }
}