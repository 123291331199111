.event-report {
  &__row {
    &:hover {
      background: #E8EAF6;
      cursor: pointer;
    }
  }

  &__filters-buttons {
    text-align: right;
  }

  .filters-frame {
    background-color: #FFFFFF;

    .md-input-date {

      .md-errors-spacer {
        display: none;
      }
    }

    .filters {
      height: 50px;
    }
  }

  .page-select {
    &.ng-scope {
      width: auto;
    }
  }

  .limit-select {
    &.ng-scope {
      width: auto;
    }
  }

  table.md-table {
    
    td.md-cell,
    th.md-column {
      padding-right: 0 !important;

      vertical-align: middle;
      text-align: left !important;
    }

    .td-event-type {
      width: 120px;
    }

    .td-event-time,
    .td-event-type,
    .td-centroid {
      font-size: 14px;
    }

    .event-occurrence {

      .td-event-type,
      [class*="icon-"] {
        @extend .red-500-fg;
      }
    }

    .event-subevent {

      .td-event-type,
      [class*="icon-"] {
        @extend .orange-500-fg;
      }
    }
  }

  &__assessment {
    display: flex;
    padding: 20px;
    padding-top: 10px;
    flex-wrap: wrap;

    &__text {
      width: 60%;

      &__row {
        display: flex;
        line-height: 50px;

        &__title {
          width: 40%;
          font-weight: bold;
        }
      }
    }
    &__images {
      width: 40%;

      &__title {
        font-weight: bold;
        line-height: 30px;

        &__info {
          font-weight: normal;
        }
      }

      &__wrapper {
        padding-bottom: 10px;
        &__img {
          border: solid 1px #455A64;
          max-width: 100%;
          max-height: 240px;
          position: relative;
          z-index: 2;
        }
      }
    }
  }
}

.custom-dialog {
  max-height: 100%;
  width: 55%;
}