/**
* Copyright 2015 Google Inc. All Rights Reserved.
*
* Licensed under the Apache License, Version 2.0 (the "License");
* you may not use this file except in compliance with the License.
* You may obtain a copy of the License at
*
*      http://www.apache.org/licenses/LICENSE-2.0
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS,
* WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
* See the License for the specific language governing permissions and
* limitations under the License.
*/

$sideNavClosedWidth: 50px;

.admin {
  font-family: 'Roboto', 'Helvetica', sans-serif;
  width: 100%;
  height: 100%;

  &__section {
    width: 100%;
    padding-left: 0;
    box-sizing: border-box;
  }

  &__link {
    border-radius: 0 !important;

    &__icon {
      margin-right: 5px;
    }
  }

  &__arrow {
    transition: transform 0.5s;

    &--rotated {
      transform: rotate(-180deg);
    }
  }

  &__sublist {
    background: #37474F;
    list-style: none;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s;

    &--opened {
      max-height: 440px;
    }

    .md-button {
      text-transform: none !important;
      padding-left: 20px !important;
      box-sizing: border-box;
    }
  }

  .mdl-layout__header {
    min-height: 52px;
  }

  .mdl-layout__header-row {
    height: 45px;
  }

  .mdl-layout__drawer {
    width: 240px;
  }

  .mdl-layout__drawer .mdl-navigation .mdl-navigation__link {
    padding: 10px;
  }

  .mdl-layout__content {
    height: 100%;
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .demo-avatar {
    width: 48px;
    height: 48px;
    border-radius: 24px;
  }
  .demo-layout .demo-header .mdl-textfield {
    padding: 0;
    margin-top: 41px;
  }
  .demo-layout .demo-header .mdl-textfield .mdl-textfield__expandable-holder {
    bottom: 19px;
  }
  .demo-layout .mdl-layout__header .mdl-layout__drawer-button {
    color: rgba(0, 0, 0, 0.54);
  }
  .mdl-layout__drawer .avatar {
    margin-bottom: 16px;
  }

  .demo-drawer {
    border: none;

    overflow-x: hidden;

    @include prefix(transition, width .3s ease-in-out);

    &.side-nav-closed {
      width: $sideNavClosedWidth;

      z-index: 10;

      .demo-drawer-header {
        padding-left: 5px;
        padding-right: 5px;
      }
    }
  }

  .main-content {
    margin-left: 0;

    @include prefix(transition, margin-left .3s ease-in-out);

    &.side-nav-closed {
      margin-left: $sideNavClosedWidth !important;
    }
  }

  .div-button-side-nav-collapse {
    position: absolute;
    top: 8px;
    left: 190px;

    width: 50px;

    z-index: 9;

    button {
      min-width: auto;

      padding: 4px;

      background-color: #263238;
      color: #FFF !important;
    }

    &.side-nav-closed {
      left: 40px;
    }
  }

  /* iOS Safari specific workaround */
  .demo-drawer .mdl-menu__container {
    z-index: -1;
  }
  .demo-drawer .demo-navigation {
    z-index: -2;
  }
  /* END iOS Safari specific workaround */
  .demo-drawer .mdl-menu .mdl-menu__item {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .demo-drawer-header {
    box-sizing: border-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;

    padding: 8px;
  }
  .demo-avatar-dropdown {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
  }

  .demo-navigation {
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }
  .demo-layout .demo-navigation .mdl-navigation__link {
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    color: white;
    font-weight: 500;
  }
  .demo-layout .demo-navigation .mdl-navigation__link:hover {
    background-color: #00BCD4;
    color: white;
  }
  .demo-layout .demo-navigation .mdl-navigation__link--active {
    background-color: #607D8B;
    color: white;
  }
  .demo-layout .demo-navigation .mdl-navigation__link--subactive {
    background-color: #90A4AE;
    color: white;
  }
  .demo-navigation .mdl-navigation__link .material-icons {
    font-size: 24px;
    color: white;
  }

  .demo-content {
    height: 100%;

    padding: 0 !important;
  }

  .demo-charts {
    -webkit-align-items: center;
    -ms-flex-align: center;
    -ms-grid-row-align: center;
    align-items: center;
  }
  .demo-chart:nth-child(1) {
    color: #ACEC00;
  }
  .demo-chart:nth-child(2) {
    color: #00BBD6;
  }
  .demo-chart:nth-child(3) {
    color: #BA65C9;
  }
  .demo-chart:nth-child(4) {
    color: #EF3C79;
  }
  .demo-graphs {
    padding: 16px 32px;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }
  /* TODO: Find a proper solution to have the graphs
  * not float around outside their container in IE10/11.
  * Using a browserhacks.com solution for now.
  */
  _:-ms-input-placeholder, :root .demo-graphs {
    min-height: 664px;
  }
  _:-ms-input-placeholder, :root .demo-graph {
    max-height: 300px;
  }
  /* TODO end */
  .demo-graph:nth-child(1) {
    color: #00b9d8;
  }
  .demo-graph:nth-child(2) {
    color: #d9006e;
  }

  .demo-cards {
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    -ms-grid-row-align: flex-start;
    align-items: flex-start;
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
  }
  .demo-cards .demo-separator {
    height: 32px;
  }
  .demo-cards .mdl-card__title.mdl-card__title {
    color: white;
    font-size: 24px;
    font-weight: 400;
  }
  .demo-cards ul {
    padding: 0;
  }
  .demo-cards h3 {
    font-size: 1em;
  }
  .demo-updates .mdl-card__title {
    background-position: 90% 100%;
    background-repeat: no-repeat;
  }
  .demo-cards .mdl-card__actions a {
    color: #00BCD4;
    text-decoration: none;
  }

  .demo-options h3 {
    margin: 0;
  }
  .demo-options .mdl-checkbox__box-outline {
    border-color: white;
  }
  .demo-options ul {
    margin: 0;
    list-style-type: none;
  }
  .demo-options li {
    margin: 4px 0;
  }
  .demo-options .material-icons {
    color: white;
  }
  .demo-options .mdl-card__actions {
    height: 64px;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    box-sizing: border-box;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .mdl-textfield--expandable .mdl-button--icon {
    top: -20px;
  }
}

@media screen and (min-width: $layout-breakpoint-sm) {
  .mdl-layout--fixed-drawer>.mdl-layout__drawer {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@media screen and (max-width: $layout-breakpoint-sm) {
    .main-content {
      margin-left: 0 !important;
    }
}

@media print {
  .admin {
    .mdl-layout {
      overflow: visible !important;
    }
    .mdl-layout__content {
      overflow: visible !important;
    }
  }
}