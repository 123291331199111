.poi-selection {
  padding: 10px;
  box-sizing: border-box;
  user-select: none;
  overflow: hidden;
  display: flex;

  &__data {
    min-width: 450px;
    margin: 10px;
  }

  &__save {
    text-align: right;
    margin-right: 15px;
  }

  &__map {
    margin: auto;
    width: 350px;
    height: 200px;
    margin-top: 10px;
  }

  &__autocomplete-template {
    li {
      border-bottom: 1px solid #ccc;
      height: auto;
      padding-top: 8px;
      padding-bottom: 8px;
      white-space: normal;
    }

    li:last-child {
      border-bottom-width: 0;
    }

    &__item-title {
      display: block;
      line-height: 2;
    }

    &__item-metadata {
      display: block;
      line-height: 2;

      &__poi-type {
        // text-align: right
      }
    }
  }
}
