.tracker-reading-events-total {
  min-width: 350px;

  .info {
    .disabled {
      opacity: .4;
    }
  }

  > div {
    margin: 0 8px;

    &:first-of-type {
      margin-left: 0;
    }
  }
}