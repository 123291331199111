#toolbar {
  height: $toolbarHeight;
  min-height: $toolbarHeight;
  max-height: $toolbarHeight;
  z-index: 55 !important;

  #navigation-toggle {
    width: $toolbarHeight;
    min-width: $toolbarHeight;
    height: $toolbarHeight;
    margin: 0;
    border-right: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 0;
  }

  .div-branch-organization-button {
    button {
      margin: 0;
    }
  }

  .user-menu {
    height: $toolbarHeight;
    padding: 0;

    md-menu-item .md-button {
      font-size: 15px;

      md-icon {
        margin: auto 16px auto 0;
      }
    }

    .user-button {
      margin: 0;
      padding: 0 16px;
      height: $toolbarHeight;
      text-transform: none;

      .avatar-wrapper {
        position: relative;

        .status {
          position: absolute;
          bottom: -3px;
          right: 5px;
        }
      }

      .username {
        margin: 0 12px 0 8px;
      }
    }

    .logout-second-user{
      min-width: 10px;
    }
  }

  .toolbar-separator {
    height: $toolbarHeight;
    width: 1px;
    background: rgba(0, 0, 0, 0.12);
  }
}

// RESPONSIVE
@media screen and (max-width: $layout-breakpoint-xs) {

  #toolbar {

    .user-menu {

      .user-button {
        min-width: 64px;
        padding: 0 2px 0 10px;
      }
    }
  }
}