/* Do not remove the comments below. It's the markers used by wiredep to inject
sass dependencies when defined in the bower.json of your dependencies */
// bower:scss
@import "../../bower_components/mdi/scss/materialdesignicons.scss";
// endbower

@import 'core/scss/global';
@import 'fonts';
@import 'variables';

.browsehappy {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

.md-datepicker-expand-triangle {
  width: auto !important;
  height: auto !important;
}

* {
  padding: 0;
  margin: 0;

  outline: none;
}

body {
  overflow-x: hidden;
}

html,
body,
body [ui-view],
body div[ui-view] div.ng-scope {
  width: 100%;
  height: 100%;
}

a {
  text-decoration: none;
  cursor: pointer;
}

.validation-messages {
  font-size: 12px;
  color: #dd2c00;
  margin: 10px 0 0 25px;
}

.hint-messages {
  position: absolute;
  left: 2px;
  right: auto;
  bottom: 7px;
  font-size: 12px;
  line-height: 14px;
  transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
  color: grey;
}

.gm-style a img {
  display: none;
}

.dtp table.dtp-picker-days tr > td > a.selected,
.dtp .dtp-hand.on,
.dtp div.dtp-date,
.dtp div.dtp-time
 {
  background: $san-marino;
}

.dtp > .dtp-content > .dtp-date-view > header.dtp-header,
.dtp .dtp-picker-time > a.dtp-select-hour.selected,
.dtp .dtp-actual-meridien a.selected {
  background: $sapphire;
}

.list-item {
  position: relative;
  height: auto !important;

  padding: 16px;

  border-bottom: 1px solid rgba(0,0,0,.08);
  text-transform: none;

  cursor: pointer;

  .avatar {
    font-size: 14px;
  }

  .info {
    margin: 0 16px 0 8px;

    .title {
      font-size: 15px;
      font-weight: 500;
    }

    .secondary-title {
      margin-left: 5px;

      font-size: 12px !important;
    }
  }

  .secondary-text {
    md-icon {
      margin: 0;
    }
  }

  &.selected {
    background-color: rgba(0, 0, 0, .06);
  }
}

.empty-list {
  height: 100%;
}

.hide-validation-error {
  .md-errors-spacer {
    display: none;
  }
}

.div-loading {
  position: absolute;
  left: 0;
  top: 0;

  width: 100%;
  height: 100%;

  display: flex;
  flex-flow: row nowrap;

  -webkit-box-align: center;
  justify-content: center;
  align-items: center;

  opacity: .6;
  z-index: 9;

  background-color: white;

  .div-icon-loading{
    display: flex;
  }
}

.avatar {
  width: 40px;
  min-width: 40px;
  height: 40px;

  line-height: 40px;
  margin: 0 8px 0 0;
  border-radius: 50%;

  font-size: 17px;
  font-weight: 500;
  text-align: center;

  color: #fff;
}

.div-floating-list-button {
  position: fixed;

  bottom: 12px;
  right: 16px;

  &.dialog {
    bottom: 56px;
    right: 0;

    animation-name: floatingButtonDialogFirefoxFix;
    animation-duration: .1s;
  }
}

.md-button[disabled] {
  opacity: .4 !important;
}

.date-container {
  padding: 16px 24px;
}

.div-list-controls {

  .order-by-directive {
    margin-right: 5px;
  }
}

md-dialog {

  .md-title {
    line-height: 1.4em;
  }
}

.md-datepicker-input-container {
  margin-left: 0 !important;

  border-bottom-width: 0;

  &.md-datepicker-focused {
    border-bottom-width: 0;
  }

  .md-datepicker-input {
    border-bottom: none;
  }
}

md-toast.success-toast .md-toast-content {
  //background-color: #138522;
  color: white;
}

md-toast.error-toast .md-toast-content {
  //background-color: #f44336;
  color: white;
}

.print-only {
  display: none !important;
}

md-select-menu {

  md-select-header {
    display: flex;
    width: auto;
    height: 48px;

    padding-left: 10.667px;

    cursor: pointer;
    position: relative;
    align-items: center;

    box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0.14), 0 0 0 0 rgba(0, 0, 0, 0.12);

    > input {
      width: 100%;
      height: 100%;

      padding: 0;

      border: none;
      outline: none;
    }
  }

  md-optgroup {
    padding-top: 12px;

    > label {
      text-transform: none;
      padding-top: 5px;
    }

    > span {
      padding: 12px;

      font-size: 14px;
    }
  }
}

@keyframes floatingButtonDialogFirefoxFix {
  from {position: absolute;}
  to {position: fixed;}
}

@media screen and (min-width: $layout-breakpoint-sm) {
  .div-list-controls {
    min-width: 350px;
    max-width: 350px;
  }
}

@media screen and (max-width: $layout-breakpoint-sm) {
  .div-list-controls {
    min-width: 150px;
    max-width: 150px;

    > * {
      max-height: 30px;
    }

    .pagination-directive {
      margin-top: 5px;
    }

    .order-by-directive {
      md-input-container {
        margin: 0;

        label {
          display: none;
        }
      }
    }
  }
}

@media screen and (max-width: $layout-breakpoint-xs) {
  .md-dialog-container {
    height: 100% !important;
  }

  md-dialog {
    max-width: 100% !important;
    max-height: 100% !important;
    width: 100% !important;
    height: 100% !important;

    form,
    md-dialog-content {
      height: 100% !important;
    }
  }
}

@media print {

  .no-printable {
    display: none !important;
  }

  .print-only {
    display: block !important;
  }
}

/* Do not remove the comments below. It's the markers used by gulp-inject to inject
all your sass files automatically */
// injector
@import "core/maps/maps.scss";
@import "core/scss/global.scss";
@import "core/directives/genericSearchAutocomplete/genericSearchAutocomplete.scss";
@import "core/scss/partials/colors.scss";
@import "core/scss/partials/helpers.scss";
@import "core/scss/partials/icons.scss";
@import "core/scss/partials/material.scss";
@import "core/scss/partials/mixin.scss";
@import "core/scss/partials/typography.scss";
@import "core/scss/partials/variables.scss";
@import "admin/action/sections/create/create.scss";
@import "admin/action/sections/list/list.scss";
@import "admin/allocation/proData/list/list.scss";
@import "admin/allocation/proData/view/view.scss";
@import "admin/allocation/report/list/list.scss";
@import "admin/allocation/report/view/view.scss";
@import "admin/allocation/sections/edit/edit.scss";
@import "admin/allocation/sections/list/list.scss";
@import "admin/allocationMonitrip/report/list/list.scss";
@import "admin/allocationMonitrip/report/view/view.scss";
@import "admin/centroid/report/known/known.scss";
@import "admin/centroid/report/unknown/unknown.scss";
@import "admin/centroid/sections/edit/edit.scss";
@import "admin/centroid/sections/list/list.scss";
@import "admin/contract/sections/edit/edit.scss";
@import "admin/contract/sections/list/list.scss";
@import "admin/contract/sections/view/view.scss";
@import "admin/line/sections/edit/edit.scss";
@import "admin/line/sections/list/list.scss";
@import "admin/employee/sections/edit/edit.scss";
@import "admin/employee/sections/list/list.scss";
@import "admin/modelOperationLog/report/list/list.scss";
@import "admin/modelOperationLog/report/view/view.scss";
@import "admin/organization/directives/organizationSelect/organizationSelect.scss";
@import "admin/organization/sections/edit/edit.scss";
@import "admin/simCard/sections/edit/edit.scss";
@import "admin/simCard/sections/list/list.scss";
@import "admin/simCard/sections/view/view.scss";
@import "admin/service/sections/edit/edit.scss";
@import "admin/service/sections/list/list.scss";
@import "admin/technicalInfo/report/list/list.scss";
@import "admin/terminal/dashboard/camera/dashboard-camera.scss";
@import "admin/terminal/dashboard/terminals-criticals/dashboard-terminals-criticals.scss";
@import "admin/terminal/dashboard/terminals-off/dashboard-terminals-off.scss";
@import "admin/terminal/dashboard/terminals-ok/dashboard-terminals-ok.scss";
@import "admin/terminal/installation/branch/installation-branch.scss";
@import "admin/terminal/installation/organization/installation-organization.scss";
@import "admin/terminal/installation/terminal/installation-terminal.scss";
@import "admin/terminal/sections/edit/edit.scss";
@import "admin/terminal/sections/list/list.scss";
@import "admin/trackerReading/dialogs/trackerReadingsImages/trackerReadingsImages-list.scss";
@import "admin/trackerReading/directives/eventsTotal/eventsTotal.scss";
@import "admin/trackerReading/directives/peopleGraph/peopleGraph.scss";
@import "admin/trackerReading/report/list/list.scss";
@import "admin/trackerReading/sections/balancing/balancing.scss";
@import "admin/user/sections/edit/edit.scss";
@import "admin/user/sections/list/list.scss";
@import "admin/user/sections/password/password.scss";
@import "admin/allocation/proData/list/templates/allocations-loading.tpl.scss";
@import "admin/allocation/proData/templates/filters/allocation-filters.scss";
@import "admin/allocation/proData/view/templates/trackerReadings-loading.scss";
@import "admin/allocation/report/dialogs/allocationTrackerReadings-list/allocationTrackerReadings-list.scss";
@import "admin/allocation/report/templates/timeline/item.scss";
@import "admin/allocation/report/templates/sidenav/allocation-sidenav.scss";
@import "admin/allocation/sections/templates/filters/allocation-filters.scss";
@import "admin/allocationMonitrip/report/dialogs/allocationTrackerReadings-list/allocationTrackerReadings-list.scss";
@import "admin/allocationMonitrip/report/templates/sidenav/allocationMonitrip-sidenav.scss";
@import "admin/centroid/report/templates/filters/centroid-filters.scss";
@import "admin/centroid/sections/templates/filters/centroid-filters.scss";
@import "admin/contract/sections/templates/filters/contract-filters.scss";
@import "admin/line/sections/templates/filters/line-filters.scss";
@import "admin/employee/sections/templates/filters/employee-filters.scss";
@import "admin/modelOperationLog/report/list/templates/print-loading.tpl.scss";
@import "admin/modelOperationLog/report/templates/filters/modelOperationLog-filters.scss";
@import "admin/reports/service/dialogs/trackerReadings-list/trackerReadings-list.scss";
@import "admin/simCard/sections/templates/filters/simCard-filters.scss";
@import "admin/service/sections/templates/filters/service-filters.scss";
@import "admin/technicalInfo/report/templates/filters/technicalInfo-filters.scss";
@import "admin/terminal/dashboard/dialogs/view/terminal-view.scss";
@import "admin/terminal/dashboard/templates/filters/terminal-filters.scss";
@import "admin/terminal/sections/templates/filters/terminal-filters.scss";
@import "admin/trackerReading/report/templates/filters/trackerReading-filters.scss";
@import "admin/trackerReading/report/templates/timeline/item.scss";
@import "admin/user/sections/templates/filters/user-filters.scss";
@import "platform/widgets/sidebar/sections/centroid/centroid-view.scss";
@import "platform/widgets/sidebar/sections/events/events-view.scss";
@import "platform/widgets/sidebar/sections/path/path.scss";
@import "platform/widgets/sidebar/sections/slideshow/slideshow.scss";
@import "platform/widgets/sidebar/sections/technical/technical.scss";
@import "admin/allocation/proData/import/dialogs/list/allocationProData-list.scss";
@import "admin/allocation/proData/view/dialogs/allocationTrackerReadings-list/allocationTrackerReadings-list.scss";
@import "admin/allocation/report/dialogs/allocationTrackerReadings-list/templates/trackerReadings-loading.scss";
@import "admin/allocation/sections/csv/dialogs/list/allocationCSV-list.scss";
@import "admin/allocation/sections/csv/dialogs/import/allocationCSV-import.scss";
@import "admin/allocation/sections/csv/dialogs/tickets-list/allocationTicketsCentroidsCSV-list.scss";
@import "admin/allocation/sections/edit/dialogs/edit/allocation-edit.scss";
@import "admin/allocationMonitrip/report/dialogs/allocationTrackerReadings-list/templates/trackerReadings-loading.scss";
@import "admin/centroid/components/eventsGraphDialog/dialogs/view/eventsGraphDialog-view.scss";
@import "admin/centroid/sections/csv/dialogs/import/centroidCSV-import.scss";
@import "admin/centroid/sections/csv/dialogs/list/centroidCSV-list.scss";
@import "admin/centroid/sections/edit/dialogs/edit/centroid-edit.scss";
@import "admin/contract/sections/edit/dialogs/edit/contract-edit.scss";
@import "admin/line/sections/csv/dialogs/import/lineCSV-import.scss";
@import "admin/line/sections/csv/dialogs/list/lineCSV-list.scss";
@import "admin/line/sections/edit/dialogs/edit/line-edit.scss";
@import "admin/employee/sections/csv/dialogs/import/employeeCSV-import.scss";
@import "admin/employee/sections/csv/dialogs/list/employeeCSV-list.scss";
@import "admin/employee/sections/edit/dialogs/edit/employee-edit.scss";
@import "admin/modelOperationLog/report/list/templates/table/table.scss";
@import "admin/organization/sections/edit/dialogs/edit/organization-edit.scss";
@import "admin/simCard/components/simCardInstallation/dialogs/confirm/simCardInstallation-confirm.scss";
@import "admin/simCard/components/simCardInstallation/dialogs/list/simCardInstallation-list.scss";
@import "admin/simCard/sections/edit/dialogs/edit/simCard-edit.scss";
@import "admin/simCard/sections/edit/dialogs/edit/simCardOrganization-edit.scss";
@import "admin/service/sections/csv/dialogs/import/serviceCSV-import.scss";
@import "admin/service/sections/csv/dialogs/list/serviceCSV-list.scss";
@import "admin/service/sections/edit/dialogs/edit/service-edit.scss";
@import "admin/terminal/installation/terminal/dialogs/camera/terminals-camera.scss";
@import "admin/terminal/installation/terminal/dialogs/move/terminals-move.scss";
@import "admin/terminal/sections/edit/dialogs/edit/terminal-edit.scss";
@import "admin/terminal/sections/updateVersion/dialogs/updateVersion/terminals-update-version.scss";
@import "admin/user/sections/edit/dialogs/edit/user-edit.scss";
@import "admin/user/sections/password/dialogs/password/user-password.scss";
@import "admin/terminal/installation/terminal/dialogs/camera/import/terminalConfigurations-import-list.scss";
@import "admin/terminal/installation/terminal/dialogs/camera/terminalModels/terminalModels-select-list.scss";
@import "fonts.scss";
@import "variables.scss";
@import "admin/admin.scss";
@import "platform/platform.scss";
@import "toolbar/toolbar.scss";
@import "admin/action/action.scss";
@import "admin/allocation/allocation.scss";
@import "admin/allocationMonitrip/allocationMonitrip.scss";
@import "admin/centroid/centroid.scss";
@import "admin/contract/contract.scss";
@import "admin/line/line.scss";
@import "admin/employee/employee.scss";
@import "admin/modelOperationLog/modelOperationLog.scss";
@import "admin/organization/organization.scss";
@import "admin/simCard/simCard.scss";
@import "admin/service/service.scss";
@import "admin/technicalInfo/technicalInfo.scss";
@import "admin/terminal/terminal.scss";
@import "admin/trackerReading/trackerReading.scss";
@import "admin/user/user.scss";
@import "home/index/index.scss";
@import "home/login/login.scss";
@import "platform/dashboard/dashboard.scss";
@import "toolbar/templates/nav-bar-menu-bar.scss";
@import "toolbar/templates/user-bar.scss";
@import "admin/allocation/proData/proDataReport.scss";
@import "admin/allocation/report/report.scss";
@import "admin/allocation/sections/sections.scss";
@import "admin/allocationMonitrip/report/report.scss";
@import "admin/centroid/report/report.scss";
@import "admin/centroid/sections/sections.scss";
@import "admin/contract/sections/sections.scss";
@import "admin/line/sections/sections.scss";
@import "admin/employee/sections/sections.scss";
@import "admin/occurrence/assessment/assessment.scss";
@import "admin/modelOperationLog/report/report.scss";
@import "admin/occurrence/violation/violation.scss";
@import "admin/reports/anomaly/anomaly.scss";
@import "admin/reports/assessment/assessment.scss";
@import "admin/reports/event/event.scss";
@import "admin/reports/service/service.scss";
@import "admin/reports/technical/technical.scss";
@import "admin/organization/sections/sections.scss";
@import "admin/simCard/sections/sections.scss";
@import "admin/service/sections/sections.scss";
@import "admin/technicalInfo/report/report.scss";
@import "admin/terminal/dashboard/dashboard.scss";
@import "admin/terminal/installation/installation.scss";
@import "admin/terminal/sections/sections.scss";
@import "admin/trackerReading/report/report.scss";
@import "admin/trackerReading/sections/sections.scss";
@import "admin/trackerReading/templates/trackerReading-view.scss";
@import "admin/user/sections/sections.scss";
@import "admin/widgets/action_editor/action_editor.scss";
@import "admin/widgets/employee_editor/employee_editor.scss";
@import "admin/widgets/line_visualizer/line_visualizer.scss";
@import "admin/widgets/poi_editor/poi_editor.scss";
@import "admin/widgets/poi_selection/poi_selection.scss";
@import "common/components/terminalInfo/terminalInfo.scss";
@import "common/components/toastLoading/toastLoading.scss";
@import "common/directives/helpIcon/helpIcon.scss";
@import "common/directives/orderBy/orderBy.scss";
@import "common/directives/pagination/pagination.scss";
@import "platform/widgets/searchbar/searchbar.scss";
@import "platform/widgets/sidebar/sidebar.scss";
@import "toolbar/dialogs/branchOrganizations/branchOrganizations-list.scss";
@import "toolbar/layouts/content-with-toolbar/toolbar.scss";
@import "toolbar/layouts/vertical-navigation/toolbar.scss";
// endinjector
