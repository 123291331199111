.line-selected-before {
  border-left: 6px solid #2196F3;
  height: 5px;
  margin-left: 6px;
}

.line-before {
  border-left: 3px solid #cecece;
  height: 5px;
  margin-left: 8px;
}

.timeline-button-apply {
  width: 90%;
}

.icon-arrow-back {
  padding-left: 4px;
  padding-right: 6px;
}

.icon-arrow-forward {
  padding-left: 6px;
  padding-right: 4px;
}

.timeline-date-control {
  width: 100%;
}

.date-control {
  float: left;
  width: 32.3%;
  margin: 0.5px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.date-prev {
  text-align: left;
  border-bottom: 1px #bdbdbd solid;
}

.date-current {
  text-align: center;
  cursor: default;
  font-weight: bold;
  font-size: medium;
  border-top: 1px #bdbdbd solid;
  border-left: 1px #bdbdbd solid;
  border-right: 1px #bdbdbd solid;
}

.date-next {
  text-align: right;
  border-bottom: 1px #bdbdbd solid;
}

.timeline-container {
  width: 100%;
  overflow-x: auto;
  overflow-x: hidden;
  padding-top: 5px;
}

.text-muted {
  color: #999;
}

small, .small {
  font-size: 85%;
}

.timeline {
  list-style: none;
  padding: 0px 0 10px;
  position: relative;
  &:before {
    top: 0;
    bottom: 0;
    position: absolute;
    content: " ";
    width: 3px;
    background-color: #cecece;
    left: 3%;
    margin-left: -1.5px;
  }
}

.timeline-bar-selected {
  list-style: none;
  padding: 20px 0 20px;
  position: relative;
  &:before {
    top: 0;
    bottom: 0;
    position: absolute;
    content: " ";
    width: 6px;
    background-color: #2196F3;
    left: 0%;
    margin-left: -1.5px;
  }
}

.timeline-selected:before {
  top: 0;
  bottom: 0;
  position: absolute;
  content: " ";
  width: 6px;
  background-color: #2196F3;
  left: 0%;
  margin-left: -9px;
  margin-bottom: -20px;
}

.timeline > li {
  margin-bottom: 10px;
  position: relative;
  &:before {
    content: " ";
    display: table;
  }
  &:after {
    content: " ";
    display: table;
    clear: both;
  }
  &:before {
    content: " ";
    display: table;
  }
  &:after {
    content: " ";
    display: table;
    clear: both;
  }
  > {
    .timeline-panel {
      float: left;
      border: 1px solid #d4d4d4;
      border-radius: 2px;
      padding: 5px;
      margin-left: 4.5%;
      position: relative;
      -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
      box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
      width: 90%;
      cursor: pointer;
      &:before {
        position: absolute;
        right: -15px;
        display: inline-block;
        border-top: 15px solid transparent;
        border-left: 15px solid #ccc;
        border-right: 0 solid #ccc;
        border-bottom: 15px solid transparent;
        content: " ";
      }
    }
    .timeline-badge {
      color: #fff;
      width: 15px;
      height: 15px;
      line-height: 50px;
      font-size: 1.4em;
      text-align: center;
      position: absolute;
      top: -1px;
      left: 3%;
      margin-left: -8px;
      background-color: #cecece;
      z-index: 100;
      border-top-right-radius: 50%;
      border-top-left-radius: 50%;
      border-bottom-right-radius: 50%;
      border-bottom-left-radius: 50%;
    }
  }
  &.timeline-inverted > .timeline-panel {
    /*float: right;*/
    &:before {
      border-left-width: 0;
      right: auto;
      margin-top: 0px;
    }
    &:after {
      border-left-width: 0;
      border-right-width: 14px;
      left: -14px;
      right: auto;
    }
  }
}

.timeline-badge {
  cursor: pointer;
  &.selected {
    background-color: #2196F3 !important;
  }
}

.timeline-title {
  margin-top: 0;
  color: inherit;
  margin-bottom: 0px;
  font-size: 1.1em;
  font-weight: bold;
}

.timeline-apply {
  text-align: center;
}

.timeline-body > {
  p, ul {
    margin-bottom: 0;
  }
  p + p {
    margin-top: 5px;
  }
}

.timeline-icon {
  vertical-align: middle;
  padding-left: 4px;
  padding-right: 4px;
}

.span-date {
  vertical-align: middle;
}

.md-button.md-raised:not([disabled]):hover {
  background-color: rgba(48,79,254, 1);
  color: rgba(255,255,255,0.95);
}
