md-dialog {

  &.contract-edit {
    min-height: 400px;

    overflow: hidden;

    md-toolbar {

      .title {
        font-size: 17px;
      }
    }

    md-dialog-content {
      position: relative;
      min-height: 300px;

      padding: 16px;

      display: block;
      overflow-x: hidden;

      


      .header-contract-simCards {
        min-height: 40px;
        max-height: 40px;
      }

      .div-edit-contract {
        padding: 0 8px;
      }

      .div-simCard {
        .list-item .info .title {
            font-size: 15px;
            font-weight: 600;
        }

        font-size: 14px;
      }

      md-input-container {

        md-icon {
          color: rgba(0,0,0,0.54);
        }

        > label {
          padding-left: 0 !important;
        }
      }

      .note{
        font-style: italic;
      } 

      md-tabs:not(.md-no-tab-content):not(.md-dynamic-height) {
        min-height: 300px;
        overflow: hidden;
      }

      md-tab-content {
        overflow: hidden;
      }

      md-tab-content > div {
        overflow-x: hidden;
        padding: 16px;
      }

      mdp-time-picker {

        > div {

          > button {
            width: 32px !important;

            padding-left: 0 !important;
            padding-right: 0 !important;

            md-icon {
              margin-left: 0 !important;
              margin-right: 0 !important;
            }
          }

          md-input-container {
            margin-left: 0 !important;

            @include prefix(flex, 1);
          }
        }
      }
    }

    md-dialog-actions {
      position: relative;
      overflow-y: hidden;

      overflow-x: auto;

      border-top: 1px solid rgba(0, 0, 0, 0.12);

      background-color: rgba(0, 0, 0, 0.03);
    }
  }
}


@media screen and (max-width: $layout-breakpoint-xs) {

  md-dialog {

    &.contract-edit {
      width: 100%;
    
      .div-input-contracts {

        > :not(:first-child) {
          margin-top: 0;
        }
      }

      .div-autocomplete,
      .input-date {
        max-height: 35px;
      }

      .div-autocomplete {
        padding-left: 3px;
        margin: 18px 0;

        &:first-child,
        md-input-container {
          margin-top: 0;
        }

        md-input-container + button {
          top: 5px;
        }
      }

      md-input-container {
        max-height: 70px;
      }
    }
  }
}

@media screen and (min-width: $layout-breakpoint-xs) {

  md-dialog {

    &.contract-edit {
      min-width: 600px;
      
      .div-autocomplete {

        &:not(:first-child) {
          margin-left: 10px;
        }
      }

      md-input-container {

        &:not(:first-child) {
          margin-left: 10px;
        }
      }

      md-dialog-content {

        .div-contract-inputs {
          max-height: 70px;
        }
      }
    }
  }
}

@media screen and (max-width: $layout-breakpoint-md) {

  md-dialog {

    &.contract-edit {
      width: 80%;
    }
  }
}

@media screen and (min-width: $layout-breakpoint-sm) {

  md-dialog {

    &.contract-edit {
      max-width: 720px;
      width: 90%;
    }
  }
}

@media screen and (max-width: $layout-breakpoint-sm) {

  md-dialog {

    &.contract-edit {
      width: 90%;
    }
  }
}

@media screen and (max-height: 700px) {

  md-dialog {

    &.contract-edit {
      min-height: 550px;
      max-height: 550px;
    }
  }
}