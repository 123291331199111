$background-color: #394b6d;

#installation-terminals {

  background-color: rgb(245, 245, 245);

  .div-center {

    .header {
      height: 110px;
      min-height: 100px;
      max-height: 136px;

      padding: 12px;

      background-color: $background-color;
      color: #FFFFFF;
    }

    .header-terminals-list {
      max-height: 100px;
      min-height: auto !important;
      height: auto;

      padding: 8px 12px;

      border-bottom: 1px solid rgba(0,0,0,.12);

      .div-total {

        > md-icon {
          margin-left: 0;
          margin-right: 5px;
        }
      }
    }

    .terminals-list {
      height: 100%;

      padding-bottom: 36px;

      border-left: 1px solid rgba(0, 0, 0, 0.12);

      overflow-x: hidden;

      p {
        margin: 0;
      }

      .md-subheader .md-subheader-inner {
        padding-top: 0;
      }

      .div-terminals-group {
        width: 100%;

        @include prefix(flex-flow, row wrap);
      }
    }

    .div-floating-list-button {
        z-index: 10;
    }
  }
}

@media screen and (max-width: $layout-breakpoint-sm) {
  #installation-terminals {

    .header {

      .div-search {
        border-left: 1px solid rgba(0, 0, 0, 0.12);
      }
    }

    .center {

      .header {
        padding-left: 16px;
      }
    }

    .terminals-list {
      .div-terminals-group {
        width: 100%;

        @include prefix(flex-flow, column nowrap);
      }

    }
  }
}