#allocations-pro-data-report {
  .header-allocations-list {
    height: 64px;
    min-height: 64px !important;
    max-height: 64px;

    padding: 8px 24px;

    border-bottom: 1px solid rgba(0,0,0,.12);

    .div-total {

      > md-icon {
        margin-left: 0;
        margin-right: 5px;
      }
    }
  }

  [kb-item].kb-active{
    background: #EEEEEE;
  }

  .allocations-list {
    height: 100%;

    padding-bottom: 36px;

    border-left: 1px solid rgba(0, 0, 0, 0.12);

    overflow-x: hidden;

    p {
      margin: 0;
    }

    .md-subheader .md-subheader-inner {
      padding-top: 0;
    }
  }

  .allocation-item {
    position: relative;
    height: auto !important;

    padding: 16px;
    
    border-bottom: 1px solid rgba(0,0,0,.08);
    text-transform: none;
    
    cursor: pointer;

    .avatar {
      font-size: 14px;

      md-icon {
        color: #FFFFFF;
      }
    }

    &.completed {
      background: #EEEEEE;

      .title,
      .notes {
        color: rgba(0, 0, 0, 0.54);
      }
    }

    &.selected {
      background: #FFF8E1;
    }

    .info {
      margin: 0 16px 0 8px;

      .title {
        font-size: 15px;
        font-weight: 500;
      }

      .secondary-title {
        margin-left: 5px;

        font-size: 12px !important;
      }

      md-icon {
        margin: 0;

        margin-right: 3px;
      }
    }

    .secondary-text {
      md-icon {
        margin: 0;

        margin-right: 3px;
      }
    }
  }
}

@media print {
  * {
    -webkit-transition: none !important;
    transition: none !important;
  }
  
  #allocations-pro-data-report {
    font-family: sans-serif;
    font-size: 14px;
    .allocations-list {
      border: 0;
      
      .allocation-table {
        table {

          tr,
          th,
          td {
            font-family: sans-serif;
            font-size: 14px;
            text-align: center !important;  
          }

          thead {
            display: table-row-group !important;
            th {
              padding: 0 12px 0 0 !important;
            }
          }
  
          tbody {
            font-family: sans-serif;
            font-size: 14px;
            tr {
              margin: 200px;
            }
            
            td {
              padding: 0 12px 0 0 !important; 
            }
          }
        }

        .total-info {
          margin-top: 50px;
          font-size: 17px;
        }
      }
    }
  }
}