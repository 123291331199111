.cluster0 {
  text-align: center;
  
  z-index: 9999;
}

.marker-cluster {
  cursor: pointer;

  font-size: 12px;
  font-family: Arial, sans-serif;
  font-weight: bold;
  
  background-position: 0 0;
  
  color: black;

  &.out {
    background-image: url("../assets/img/map/out_icon.png");

    position: absolute;
    height: 19px;
    width: 45px;

    padding-top: 19px;

    &:after {
      content: ' ';

      padding-left: 5px;
    }
  }

  &.in-occurrence {
    background-image: url("../assets/img/map/in_alert_icon.png");

    position: absolute;
    height: 20px;
    width: 48px;

    padding-top: 5px;

    &:after {
      content: ' ';

      padding-left: 10px;
    }
  }
}