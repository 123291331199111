.terminal-info-component {
  height: auto !important;
  
  .terminal-item.extended {
    position: relative;
    height: auto !important;
    min-height: 20%;

    min-width: 358px;
    max-width: 358px;
    width: 358px;

    margin: 6px;

    padding: 8px 8px 16px 8px;

    text-transform: none;
    
    cursor: pointer;

    .avatar {
      font-size: 14px;
    }

    .info {
      margin: 0 16px 0 8px;

      .title {
        font-size: 15px;
        font-weight: 700;
      }

      .secondary-title {
        margin-left: 5px;

        font-size: 12px !important;
      }
    }

    .secondary-text {
      md-icon {
        margin: 0;
      }
    }

    .div-event-img {
      min-height: 215px;

      width: 325px;

      .event-img {
        z-index: 1;

        .ng-image-gallery-modal {
          height: 205px;
        }
      }

      .div-legend-img {
        position: absolute;
        bottom: 10px;
        left: 27px;

        width: 273px;

        padding: 8px;

        z-index: 1;

        color: white;
        font-size: 14px;
        font-weight: bold;

        background-color: rgba(0, 0, 0, .26);

        * {
          color: white;
        }

        .notes {
          margin-right: 0;
        }
        
        .img-icon-event {
          .avatar {
            opacity: .7;
          }
        }
      }

      .event-map-img {
        position: absolute;
        right: 0;
      }
    }

    .div-terminal-item {
      width: 325px;

      .avatar {
        font-size: 14px;
      }

      .info {
        margin: 0 8px 0 4px;

        .title {
          font-size: 15px;
          font-weight: 500;
        }

        .secondary-title {
          margin-left: 5px;

          font-size: 12px !important;
        }

        .notes {

          md-icon {
            margin-right: 5px;
          }
        }
      }

      .secondary-text {

        md-icon {
          margin: 0;
          margin-right: 5px;
        }
      }

      .div-sensors-status {
        margin: 8px 0 0 6px;

        > div {
          padding: 2px 0;
          margin: 0 2px;

          border: solid 1px;

          text-align: center;
          font-weight: bold;

          md-icon {
            margin: 0;
          }

          md-icon,
          .icon {
            margin-right: 5px;
          }
        }
      }
    }
  }

  .terminal-item.simple {
    position: relative;

    min-width: 273px;
    max-width: 273px;
    width: 273px;

    margin: 3px;

    text-transform: none;

    cursor: pointer;

    .div-event-img {
      width: 100%;

      height: 204.75px !important;
      min-height: 204.75px;

      .event-img {
        z-index: 1;

        .ng-image-gallery-modal {
          height: 205px;
        }
      }

      .galleria-image {
        margin: 0 !important;
      }

      .div-legend-img {
        position: absolute;
        bottom: 0;
        left: 0;

        width: 273px;

        padding: 5px 10px;

        z-index: 1;

        color: white;
        font-size: 14px;
        font-weight: bold;

        background-color: rgba(0, 0, 0, .26);

        * {
          color: white;
        }

        .img-icon-event {
          .avatar {
            width: 30px;
            min-width: 30px;
            height: 30px;
            line-height: 27px;

            opacity: .7;
          }
        }
      }

      .event-map-img {
        position: absolute;
        right: 0;
      }
    }

    .div-terminal-item {
      width: 273px;
      padding: 6px;
      color: white;

      .avatar {
        font-size: 14px;
      }

      .info {
        margin: 0 8px 0 4px;

        .title {
          font-size: 15px;
          font-weight: 500;
        }

        .secondary-title {
          margin-left: 5px;

          font-size: 12px !important;
        }

        .notes {

          md-icon {
            margin-right: 5px;
            color: white;
          }
        }
      }

      .secondary-text {

        md-icon {
          margin: 0;
          margin-right: 5px;
          color: white;
        }
      }

      .div-sensors-status {
        margin: 8px 0 0 6px;

        > div {
          padding: 2px 0;
          margin: 0 2px;

          border: solid 1px;

          text-align: center;
          font-weight: bold;

          md-icon {
            margin: 0;
          }

          md-icon,
          .icon {
            margin-right: 5px;
          }
        }
      }
    }
  }

  .terminal-item.compact {
    position: relative;

    height: auto !important;
    min-height: 138px ;
    max-height: 148px ;

    min-width: 358px;
    max-width: 358px;
    width: 358px;

    margin: 6px;

    padding: 8px 8px 16px;

    text-transform: none;

    cursor: pointer;

    .div-event-img {
      min-height: 215px;

      width: 100%;

      .event-img {
        z-index: 1;

        .ng-image-gallery-modal {
          height: 205px;
        }
      }

      .div-legend-img {
        position: absolute;
        bottom: 10px;
        left: 35px;

        width: 273px;

        padding: 12px;

        z-index: 1;

        color: white;
        font-size: 14px;
        font-weight: bold;

        background-color: rgba(0, 0, 0, .26);

        * {
          color: white;
        }

        .img-icon-event {
          .avatar {
            line-height: 37px;

            opacity: .7;
          }
        }
      }

      .event-map-img {
        position: absolute;
        right: 0;
      }
    }

    .div-terminal-item {
      width: 325px;

      .avatar {
        font-size: 14px;
      }

      .info {
        margin: 0 8px 0 4px;

        .title {
          font-size: 15px;
          font-weight: 500;
        }

        .secondary-title {
          margin-left: 5px;

          font-size: 12px !important;
        }

        .notes {

          md-icon {
            margin-right: 5px;
          }
        }
      }

      .secondary-text {

        md-icon {
          margin: 0;
          margin-right: 5px;
        }
      }

      .div-sensors-status {
        margin: 8px 0 0 6px;

        > div {
          padding: 2px 0;
          margin: 0 2px;

          border: solid 1px;

          text-align: center;
          font-weight: bold;

          md-icon {
            margin: 0;
          }

          md-icon,
          .icon {
            margin-right: 5px;
          }
        }
      }
    }
  }
}