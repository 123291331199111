$background-color: #394b6d;

#technical {

  background-color: rgb(245, 245, 245);

  .div-center {

    .header {
      height: 110px;
      min-height: 100px;
      max-height: 136px;

      padding: 12px;

      background-color: $background-color;
      color: #FFFFFF;
    }

    .header-terminals-list {
      height: 44px;
      min-height: 44px !important;
      max-height: 44px;

      padding: 8px 24px;

      border-bottom: 1px solid rgba(0,0,0,.12);

      .div-total {
        font-size: 14px;

        > md-icon {
          margin-left: 0;
          margin-right: 5px;
        }
      }
    }

    .terminals-list {
      height: 100%;

      padding-bottom: 36px;

      border-left: 1px solid rgba(0, 0, 0, 0.12);

      overflow-x: hidden;

      p {
        margin: 0;
      }

      .md-subheader .md-subheader-inner {
        padding-top: 0;
      }
    }

    .terminal-item {
      position: relative;
      height: auto !important;

      padding: 12px;
      padding-right: 0;
      
      border-bottom: 1px solid rgba(0,0,0,.08);
      text-transform: none;
      
      cursor: pointer;

      .avatar {
        font-size: 14px;
      }

      .info {
        margin: 0 16px 0 8px;

        .notes {
          font-size: 14px;
        }

        .title {
          font-size: 15px;
          font-weight: 700;
        }

        .secondary-title {
          margin-left: 5px;

          font-size: 12px !important;
        }
      }

      .secondary-text {
        md-icon {
          margin: 0;
        }
      }

      .div-sensors-status {
        margin: 8px 10px 0 6px;

        > div {
          padding: 2px 0;
          margin: 0 2px;

          border: solid 1px;

          text-align: center;
          font-size: 12px;
          font-weight: bold;

          // color: #FFF;

          md-icon {
            margin: 0;
          }

          md-icon,
          .icon {
            margin-right: 5px;

            // color: #FFF;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $layout-breakpoint-sm) {
  #technical {

    .header {

      .div-search {
        border-left: 1px solid rgba(0, 0, 0, 0.12);
      }
    }

    .center {

      .header {
        padding-left: 16px;
      }
    }
  }
}