md-dialog {

  &.terminal-view {
    max-width: 720px;
    min-height: 85%;
    width: 630px;

    overflow: hidden;

    .header-terminal-view {
      background-color: #263238;
      color: #fff;

      .total-terminal-view {
        max-height: 25px;
      }

      md-icon {
        margin: 0;
        color: #fff;
      }
    }

    .div-floating-list-button {
      bottom: 12px;
    }

    .avatar-tab {
      margin-bottom: 3px;
      margin-left: 5px;
      border-radius: 50%;
      color: white !important;
      font-size: 12px !important;
      width: 15px !important;
      height: 15px !important;
      min-width: 15px !important;
      min-height: 15px !important;
      line-height: 15px !important;
    }

    .location-icon-tab {
      margin-bottom: 3px;
      margin-left: 5px;
    }

    md-dialog-content {
      position: relative;

      max-height: 100%;
      min-height: 382px;

      padding: 0 !important;

      display: block;
      overflow-x: hidden;

      .list-item {
        padding-top: 12px;

        font-size: 14px;

        color: white;

        .title {
          font-size: 18px;
          font-weight: bold;

          .event-edit-button {
            margin-left: 0;
            padding-left: 0;

            font-size: 22px;
          }
        }

        .info {
          margin-left: 0;
          margin-right: 0;

          .notes {
            md-icon {
              margin-left: 0;
              margin-right: 5px;
            }
          }
        }

        .div-event-buttons {
          button {
            margin-top: 4px;
            margin-bottom: 0;
          }
        }

        md-icon {
          color: white;

          margin-right: 5px;
        }
      }

      .terminal-tabs {
        .md-tab-content {
          min-height: 200px;
        }

        .div-chart {
          position: relative;

          min-width: 100%;
          min-height: 400px;
          max-height: 450px;

          background-color: #FFFFFF;

          .no-display {
            display: none;
          }

          .title {
            margin: 0 0 12px;
            padding: 12px 12px 0;

            color: rgba(0, 0, 0, .54);
          }

          .div-line-char {
            position: relative;

            padding: 0 12px;
            padding-right: 24px;
          }

          .nvd3.nv-scatter .nv-groups .nv-point,
          .nvd3 .nv-groups .nv-point {
            stroke-width: 5px;
            fill-opacity: .95 !important;
            stroke-opacity: .95 !important;
          }

          .nv-noData {
            font-size: 16px !important;

            font-style: italic;

            @extend .text-semibold;
          }

          .no-data {
            margin-left: -40px;
          }

          .point-avatar {
            width: 5px;
            height: 5px;
            border-radius: 50%;
          }
        }

        .div-technical-info-status {
          padding: 6px;

          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
          flex-flow: row wrap;

          > div {
            min-width: 95px;
            max-width: 95px;
            width: 95px;

            min-height: 82px;
            max-height: 82px;
            height: 82px;

            padding: 2px 0;
            margin: 2px;

            text-align: center;
            font-weight: bold;

            .avatar {
              margin: 0;

              font-weight: bold;
            }

            .name-technical-info {
              flex: 1;

              padding: 6px;

              line-height: 1em;
            }
          }
        }
      }

      .div-map-img {
        padding: 6px;

        overflow: hidden;

        .div-gmaps {
          min-height: 350px;

          .gmaps {
            overflow: hidden;
          }
        }

        .div-position-not-found {
          position: absolute;
          bottom: 0;
          left: 0;

          height: 100%;
          width: 100%;

          opacity: .7;

          background-color: #FFF;

          @include prefix(transition, all .3s ease-out);

          &.loading {
            margin-bottom: -100px;
          }

          > div {
            text-align: center;
            padding: 12px;
          }
        }
      }

      md-input-container {
        margin: 0;
      }

      p {
        margin: 0;
      }

      .trackerReadings-list {
        height: 100%;
  
        padding-bottom: 36px;
  
        border-left: 1px solid rgba(0, 0, 0, 0.12);
  
        overflow-x: hidden;
        overflow-y: auto;
  
        p {
          margin: 0;
        }
  
        .div-group {
          height: auto;
  
          padding: 0 0 6px;
  
          &:not(:last-of-type) {
            border-bottom: solid 1px rgba(0, 0, 0, .08);
          }
  
          .md-subheader {
  
            .div-totals {
              min-width: 370px;
  
              .info {
                .disabled {
                  opacity: .4;
                }
              }
  
              > div {
                margin: 0 8px;
  
                &:first-of-type {
                  margin-left: 0;
                }
              }
            }
  
            .button-show-hide-trackerReadings {
              font-size: 13px;
            }
  
            .md-subheader-inner {
              padding: 8px 16px 6px;
            }
          }
  
          .div-trackerReadings-group {
            width: 100%;
  
            @include prefix(flex-flow, row wrap);
          }
        }
      }
  
      .trackerReading-item {
        position: relative;
  
        height: auto !important;
        min-height: 20%;
  
        min-width: $div-trackerReading-width !important;
        max-width: $div-trackerReading-width;
        width: $div-trackerReading-width;
  
        margin: 6px;
  
        padding: 8px 0 0;
  
        text-transform: none;
  
        cursor: pointer;
  
        .div-event-img {
          min-height: 215px;
          max-width: 330px;
  
          width: 100%;
  
          .event-img {
            z-index: 1;
  
            // .ng-image-gallery-modal {
            //   // height: 205px;
            // }
          }
  
          &:hover {
            .event-img-png {
              display: block;
  
              z-index: 2;
            }
          }
  
          .event-img-png {
            position: absolute;
            top: 0;
            left: 0;
  
            width: 100%;
            height: 97%;
  
            display: none;
  
            img {
              box-shadow: none;
            }
          }
        }
  
        .div-trackerReading-item {
          color: #FFFFFF;
  
          .avatar {
            font-size: 14px;
          }
  
          .info {
            min-width: 190px;
            max-width: 193px;
  
            margin-right: 4px;
  
            .secondary-title {
              margin-left: 5px;
  
              font-size: 12px !important;
            }
  
            .notes {
  
              md-icon {
                margin-right: 3px;
  
                color: #FFFFFF;
              }
            }
          }

          .secondary-info {
            md-icon {
              margin-right: 3px;

              color: #FFFFFF;
            }
          }
  
          .secondary-text {
  
            md-icon {
              margin: 0;
              margin-right: 5px;
            }
          }
  
          .trackerReading-address {
            max-height: 35px;
  
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }
    }

    md-dialog-actions {
      position: relative;
      overflow-y: hidden;

      overflow-x: auto;

      border-top: 1px solid rgba(0, 0, 0, 0.12);

      background-color: rgba(0, 0, 0, 0.03);
    }
  }

}

@media screen and (min-width: $layout-breakpoint-xs) {
  md-dialog {
    &.terminal-view {
      min-height: 85%;
      max-height: 100%;

      md-dialog-content {
        flex: 1 1 100%;
        display: flex;

        md-tabs {
          //min-height: 582px !important;
          flex: 1 1 100%;
        }
      }
    }
    
    &.extended-width-height {
      min-width: 95% !important;
      max-width: 100% !important;
      min-height: 95% !important;
      max-height: 100% !important;

      md-dialog-content {
        flex: 1 1 100% !important;
        display: flex;

        md-tabs {
          //min-height: 582px !important;
          flex: 1 1 100% !important;
        }
      }
    }
  }
}

@media screen and (max-width: $layout-breakpoint-sm) {
  md-dialog {
    &.terminal-view {
      md-dialog-content {
        md-tabs {
          height: 100%;
        }
      }
    }
  }
}

@media screen and (min-width: $layout-breakpoint-md) {
  md-dialog {
    &.terminal-view {
      md-dialog-content {
        md-tabs {
          .terminal-infos-header {
            position: sticky;
            width: 100%;
            z-index: 3;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
          }
        }
      }
    }
  }

}