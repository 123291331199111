$divButtonSideNavCollapseTransitions: width .3s ease-out, right .3s ease-out, top .3s ease-out;

.div-sidebar {
  .sidebar {
    &--visible {
      opacity: 1 !important;
      visibility: visible !important;
    }

    md-whiteframe {
      min-width: 382px;
      width: 382px;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      background: white;
      overflow-y: scroll;
      transition: all 0.2s;
      opacity: 0;
      visibility: hidden;
      z-index: 1;
    }

    md-tabs.md-dynamic-height,
    md-tabs-content-wrapper {
      overflow: hidden !important;
    }

    md-switch {
      margin-bottom: 0 !important;
    }

    md-input-container {
      margin: 0 !important;
      padding: 0 !important;
    }

    &__streetview {
      width: 100%;
    }

    &__legend {
      margin: 30px;
      padding: 5px;
    }

    &__container {
      width: 100%;
      margin-top: 25px;
      margin-bottom: -30px;

      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2)
        , 0px 4px 5px 0px rgba(0, 0, 0, 0.14);
    }

    &__logo {
      bottom: 10px;
      left: 10px;
      width: 130px;
      height: 25px;
      position: absolute;
      z-index: 1000;
      transition: all 0.3s;

      &--opened {
        left: 360px;
      }
    }

    &__close {
      z-index: 1;
      border: solid 1px rgba(0, 0, 0, 0.2);
      border-left-width: 0;
      background: white;
      color: black;
      position: absolute;
      width: 30px;
      height: 60px;
      top: 100px;
      left: 0;
      font-size: 1.4em;
      line-height: 60px;
      cursor: pointer;
      transition: all 0.3s;
      text-align: right;
      padding-right: 10px;
      box-sizing: border-box;
      transition-delay: 0.3s;

      &--opened {
        width: 400px;
      }
    }

    &__center {
      text-align: center;

      &__inputs {
        padding: 1em;
        box-sizing: border-box;
      }
    }

    &__placeholderlogo {
      margin: 70px auto 20px;
      display: block;
    }

    &__list {
      width: 300px;
      width: 100%;

      .md-tabs-canvas,
      md-pagination-wrapper,
      md-tabs-canvas {
        height: 70px !important;
      }

      md-tabs-content-wrapper {
        box-sizing: border-box;
      }

      &__icon {
        font-size: 1.5em;
      }

      &__placeholder {
        text-align: center;
        font-size: 1.5em;
        margin-top: 1em;

        &__icon {
          font-size: 3em;
        }
      }
    }

    md-tab-content {
      min-height: 300px;
    }

    mdp-time-picker {
      md-input-container {
        width: 100%;
        margin: 0;
      }

      .md-button.md-icon-button {
        margin: 0;
      }
    }

    .input-container-datepicker {
      margin-right: 5px !important;
    }

    .div-events-filters {

      .div-switch-ghost-event {
        cursor: pointer;
        width: 100%;
      }

      .select-filter {
        md-input-container {
          margin: 18px 0 !important;
          padding-left: 36px !important;

          md-icon {
            color: rgba(0, 0, 0, .56);
          }

          md-select {
            height: 35px !important;
          }
        }
      }
    }

    #tab-content-5 {
      padding-right: 0;
    }
  }

  .sidenav-centroid-view {
    z-index: 62;
    width: 400px;
  }

  .sidenav-events-view {
    z-index: 61;
    width: 400px;
  }

  .button-toggle-sidenav {
    z-index: 61;
  }

  .event-map-div {
    position: relative;

    box-shadow: 0 4px 5px -2px rgba(0,0,0,.2), 0 7px 10px 1px rgba(0,0,0,.14), 0 2px 16px 1px rgba(0,0,0,.12);

    z-index: 3;

    .event-map {
      position: relative;

      width: 100%;
      height: 100%;

      z-index: 0;
    }

    .div-button-toggle-view-sidenav {
      width: auto;
      height: auto;
      position: fixed;
      top: 50%;
      right: 0;

      padding: 4px 0;

      cursor: pointer;

      z-index: 3;
      background-color: rgb(255, 255, 255);

      &:hover {
        background-color: #f1f0f1;
      }
    }
  }

  .div-button-side-nav-collapse {
    position: absolute;
    top: 5px;
    right: 305px;

    width: 50px !important;
    height: auto !important;

    // z-index: 1;

    @include prefix(transition, $divButtonSideNavCollapseTransitions);

    &.side-nav-closed {
      right: 120px;

      width: auto !important;

      button {
        padding: 4px 12px;
      }
    }

    button {
      min-width: auto;

      padding: 4px;

      background-color: #263238 !important;
      color: #FFF !important;

      @include prefix(transition, width .3s ease-out);
      @include prefix(transition, opacity .3s ease-out);

      &:hover {
        opacity: .8;
      }
    }
  }

  .div-legend {
    .list-item {
      cursor: initial;
    }
  }
}

.ng-image-gallery.inline .ng-image-gallery-modal {
  position: relative;
  z-index: 1;
  overflow: hidden;
  width: 100%;
  height: 250px;
}
.ng-image-gallery.inline .ng-image-gallery-modal .ng-image-gallery-content .prev, .ng-image-gallery.inline .ng-image-gallery-modal .ng-image-gallery-content .next {
  display: block!important;
  position: absolute;
  top: -9999px;
  left: -9999px;
}

.ng-image-gallery .ng-image-gallery-modal .ng-image-gallery-content .galleria {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.ng-image-gallery .ng-image-gallery-modal .ng-image-gallery-content .galleria .galleria-bubbles {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 8px;
  flex: 0 0 8px;
  text-align: center;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.ng-image-gallery .ng-image-gallery-modal .ng-image-gallery-content .galleria .galleria-bubbles span:not(.img-bubble) {
  width: 8px;
  height: 8px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 100%;
  overflow: hidden;
  -webkit-transition: all 0s;
  transition: all 0s;
}

.ng-image-gallery .ng-image-gallery-modal .ng-image-gallery-content .galleria .galleria-bubbles span {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  position: relative;
  display: inline-block;
  margin: 1px;
  cursor: pointer;
  overflow: hidden;
  border-radius: 100%;
  background-size: cover;
  background-position: center center;
  vertical-align: middle;
}

.prev-arrow {
  float: left;
  text-align: left;
  width: 20px;
  cursor: pointer;
  outline: none;
  border: 0;
  padding: 5px;
}

.next-arrow {
  float:right;
  text-align: right;
  width: 20px;
  cursor: pointer;
  outline: none;
  border: 0;
  padding: 5px;
}

.image-slider-status {
  text-align: center;
  font-weight: bold;
  line-height: 2.5;
}

.image-control {
  cursor: pointer;
  outline: none;
  border: 0;
}

.image-address {
  height: 30px;
}

.image-controls {
  float: left;
  width: 31%;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.last-your {
  &__table {
    width: 100%;
    text-align: left;
    font-size: 0.9em;
    tr {
      line-height: 1.5em;
    }
    th {
      padding-right: 6px;
    }
  }
}

.real_time_header {
  text-align: center;
}

.no-events {
  font-weight: 500;
  padding: 10px;
  text-align: center;
}

.image-slideshow-address {
  overflow: hidden;
  position: relative;
  line-height: 1.2em;
  max-height: 2.4em;
  text-align: justify;
  padding-right: 1em;
  margin: 0;

  &:before {
    content: '...';
    position: absolute;
    right: 0;
    bottom: 0;
  }

  &:after {
    content: '';
    position: absolute;
    right: 0;
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    background: white;
  }
}

.md-input-date {
  width: 50%;
  float:left;
}

.md-input-date-icon {
  float:left;
}

.md-input-date-date {
  width: 70%;
  float: left;
  margin-left: 5px;
}

.md-input-time {
  width: 50%;
  float:left;
}

.date-checkbox-now {
  margin-top: -20px;
  float: right;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

md-checkbox.md-checked.date-checkbox-now .md-icon {
 background-color: rgb(63,81,181);
}

@media screen and (max-width: $layout-breakpoint-xs) {
  .div-sidebar {
    .sidebar {
      .sidebar__close {

        &--opened {
          max-width: 94%;
          width: 94%;
        }
      }

      md-whiteframe {
        max-width: 85%;
        width: 85%;

        z-index: 2;

        .ng-image-gallery {

          .next-arrow {
            padding-right: 0;
          }
        }
      }
    }

    .sidenav-centroid-view {
      max-width: 100%;
      width: 100%;
    }

    .div-button-side-nav-collapse {
      &:not(.side-nav-closed) {
        display: none;
      }

      &.side-nav-closed {
        top: 100px;
        right: 2px;
      }
    }

    .sidenav-centroid-view,
    .sidenav-events-view {
      max-width: 100%;
      width: 100% !important;
    }
  }
}

@media print {
  .div-sidebar {

    &--centroid-sidenav-open {
      .sidenav-events-view {
        display: none !important;
      }
    }

    .sidebar__logo {
      display: none;
    }

    .sidenav-events-view {
      min-width: 100% !important;
      width: 100% !important;
    }

    .sidenav-centroid-view {
      max-width: 100% !important;
      width: 100% !important;
    }
  }
}